<div class="container">
  <div class="row d-flex justify-content-center">
    <form class="col-md-12 mt-5 card pt-3 pb-3 mb-5">
      <div [formGroup]="mainForm" class="row">

        <div class="col-md-12 mb-2">
          <div class="btn-group btn-group-toggle mr-2" data-toggle="buttons">
            <label class="btn" [ngClass]="isSimpleMode() ? 'active btn-secondary' : 'btn-outline-secondary'">
              <input type="radio" name="formMode" id="simple" autocomplete="off" value="simple"
                     [checked]="isSimpleMode() ? 'checked' : ''" [formControl]="formModeSwitch"> Simple mode
            </label>
            <label class="btn" [ngClass]="!isSimpleMode() ? 'active btn-secondary' : 'btn-outline-secondary'">
              <input type="radio" name="formMode" id="full" autocomplete="off" value="full"
                     [checked]="!isSimpleMode() ? 'checked' : ''" [formControl]="formModeSwitch"> Full mode
            </label>
          </div>

          <div class="custom-control custom-switch d-flex mr-2 flex-fill" *ngIf="!isSimpleMode()">
            <input type="checkbox" class="custom-control-input" id="editClinicianExpenses"
                   [formControl]="editClinicianExpenses">
            <label class="custom-control-label" for="editClinicianExpenses">Do Clinicians travel to see
              patients?</label>
          </div>


          <div class="custom-control custom-switch d-flex" *ngIf="!isSimpleMode()">
            <input type="checkbox" class="custom-control-input" id="editServiceExpenses"
                   [formControl]="editServiceExpenses">
            <label class="custom-control-label" for="editServiceExpenses">Do you use Video Conferencing hardware
              equipments/software clients if Video Call is not available?</label>
          </div>

          <div class="d-flex" *ngIf="!isSimpleMode()">
            <b>How are we helping the Health System</b>
          </div>

        </div>

        <div [ngClass]="!isSimpleMode() ? 'col-md-3' : 'col-md-12'">
          <div class="card">
            <div class="card-header">Video Call Service Factors</div>
            <div class="card-body" [ngClass]="isSimpleMode() ? 'row align-items-end' : ''">
              <div class="form-group" [ngClass]="isSimpleMode() ? 'col-md-3' : ''">
                <label for="consults">Clinical Consults per month<span class="required-color"> *</span></label>
                <input type="text"
                       class="form-control"
                       id="consults"
                       data-toggle="tooltip" data-placement="top" title="Clinical Consults per month"
                       [formControl]="videoCall('consults')"
                       [ngClass]="{'is-invalid': videoCall('consults')?.invalid && (videoCall('consults')?.touched || videoCall('consults')?.dirty)}"
                />
                <div class="invalid-feedback">
                  Please provide valid number of Clinical Consults per month
                </div>
              </div>
              <div class="form-group" [ngClass]="isSimpleMode() ? 'col-md-3' : ''">
                <label for="meetings">Clinician or Admin Meetings per month<span
                  class="required-color"> *</span></label>
                <input type="text"
                       class="form-control"
                       id="meetings"
                       data-toggle="tooltip" data-placement="top" title="Clinician or Admin Meetings per month"
                       [formControl]="videoCall('meetings')"
                       [ngClass]="{'is-invalid': videoCall('meetings')?.invalid && (videoCall('meetings')?.touched || videoCall('meetings')?.dirty)}"
                />
                <div class="invalid-feedback">
                  Please provide valid number of Clinician or Admin Meetings per month
                </div>
              </div>
              <div class="form-group" [ngClass]="isSimpleMode() ? 'col-md-3' : ''">
                <label for="clinicalSites">Active Clinical Sites in the organisation<span
                  class="required-color"> *</span></label>
                <input type="text"
                       class="form-control"
                       id="clinicalSites"
                       data-toggle="tooltip" data-placement="top" title="Active Clinical Sites in the organisation"
                       [formControl]="videoCall('clinicalSites')"
                       [ngClass]="{'is-invalid': videoCall('clinicalSites')?.invalid && (videoCall('clinicalSites')?.touched || videoCall('clinicalSites')?.dirty)}"
                />
                <div class="invalid-feedback">
                  Please provide valid number of Active Clinical Sites in the organisation
                </div>
              </div>
              <div class="form-group" [ngClass]="isSimpleMode() ? 'col-md-3' : ''">
                <label for="clinicians">Active Clinicians conducting consults<span
                  class="required-color"> *</span></label>
                <input type="text"
                       class="form-control"
                       id="clinicians"
                       data-toggle="tooltip" data-placement="top" title="Active Clinicians conducting consults"
                       [formControl]="videoCall('clinicians')"
                       [ngClass]="{'is-invalid': videoCall('clinicians')?.invalid && (videoCall('clinicians')?.touched || videoCall('clinicians')?.dirty)}"
                />
                <div class="invalid-feedback">
                  Please provide valid number of Active Clinicians conducting consults
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3" *ngIf="!isSimpleMode()">
          <div class="card">
            <div class="card-header">How are we helping health consumers and community</div>
            <div class="card-body">
              <div class="form-group">
                <label for="patientTrip">Average Patient/Family Travel (round trip)<span class="required-color">*</span></label>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         id="patientTrip"
                         placeholder="km"
                         data-toggle="tooltip" data-placement="top" title="Average Patient/Family Travel (round trip)"
                         [formControl]="healthConsumer('patientTrip')"
                         [ngClass]="{'is-invalid': healthConsumer('patientTrip')?.invalid && (healthConsumer('patientTrip')?.touched || healthConsumer('patientTrip')?.dirty)}"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">Km</span>
                  </div>
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Average Patient/Family Travel (round trip)
                </div>
              </div>
              <div class="form-group">
                <label for="travelCost">Travel and Accommodation<span class="required-color"> *</span></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="travelCost"
                         placeholder="$"
                         data-toggle="tooltip" data-placement="top" title="Travel and Accommodation"
                         [formControl]="healthConsumer('travelCost')"
                         [ngClass]="{'is-invalid': healthConsumer('travelCost')?.invalid && (healthConsumer('travelCost')?.touched || healthConsumer('travelCost')?.dirty)}"
                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Travel and Accommodation
                </div>
              </div>
              <div class="form-group">
                <label for="lostProductivity">Patient/Family Lost productivity<span
                  class="required-color"> *</span></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="lostProductivity"
                         placeholder="$"
                         data-toggle="tooltip" data-placement="top" title="Patient/Family Lost productivity"
                         [formControl]="healthConsumer('lostProductivity')"
                         [ngClass]="{'is-invalid': healthConsumer('lostProductivity')?.invalid && (healthConsumer('lostProductivity')?.touched || healthConsumer('lostProductivity')?.dirty)}"
                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Patient/Family Lost productivity
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3" *ngIf="!isSimpleMode()">
          <div class="card" *ngIf="editClinicianExpenses.value === true">
            <div class="card-header">How are we helping the Clinicians</div>
            <div class="card-body">
              <div class="form-group">
                <label for="clinicianTravel">Average Clinician Travel(round trip)</label>
                <div class="input-group">
                  <input type="text"
                         class="form-control"
                         id="clinicianTravel"
                         placeholder="km"
                         data-toggle="tooltip" data-placement="top" title="Average Clinician Travel"
                         [formControl]="healthSystem('clinicianTravel')"
                         [ngClass]="{'is-invalid': healthSystem('clinicianTravel')?.invalid && (healthSystem('clinicianTravel')?.touched || healthSystem('clinicianTravel')?.dirty)}"
                  />

                  <div class="input-group-append">
                    <span class="input-group-text">Km</span>
                  </div>
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Average Clinician Travel
                </div>
              </div>
              <div class="form-group">
                <label for="clinicianTravelCost">Clinician Travel/Accommodation</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="clinicianTravelCost"
                         placeholder="$"
                         data-toggle="tooltip" data-placement="top" title="Clinician Travel / Accommodation Costs"
                         [formControl]="healthSystem('clinicianTravelCost')"
                         [ngClass]="{'is-invalid': healthSystem('clinicianTravelCost')?.invalid && (healthSystem('clinicianTravelCost')?.touched || healthSystem('clinicianTravelCost')?.dirty)}"
                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Clinician Travel & Accommodation Costs
                </div>
              </div>
              <div class="form-group">
                <label for="clinicianLostProductivity">Clinician Lost productivity</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="clinicianLostProductivity"
                         placeholder="$"
                         data-toggle="tooltip" data-placement="top" title="Clinician Lost productivity"
                         [formControl]="healthSystem('clinicianLostProductivity')"
                         [ngClass]="{'is-invalid': healthSystem('clinicianLostProductivity')?.invalid && (healthSystem('clinicianLostProductivity')?.touched || healthSystem('clinicianLostProductivity')?.dirty)}"
                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Clinician Lost productivity
                </div>
              </div>
              <div class="form-group">
                <label for="techSupport">Admin/tech support and logistics for travel and accommodation</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="techSupport"
                         placeholder="$"
                         data-toggle="tooltip" data-placement="top"
                         title="Admin/tech support and logistics for travel and accommodation ($)"
                         [formControl]="healthSystem('techSupport')"
                         [ngClass]="{'is-invalid': healthSystem('techSupport')?.invalid && (healthSystem('techSupport')?.touched || healthSystem('techSupport')?.dirty)}"
                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Admin/tech support and logistics for travel and accommodation
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3" *ngIf="!isSimpleMode()">
          <div class="card" *ngIf="editServiceExpenses.value === true">
            <div class="card-header">Annual Video Conferencing Hardware Equipment & Service</div>
            <div class="card-body">
              <div class="form-group">
                <label for="capitalEquipment">Hardware Equipment Cost</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="capitalEquipment"
                         data-toggle="tooltip" data-placement="top" title="Hardware Equipment Cost"
                         [formControl]="equipmentService('capitalEquipment')"
                         [ngClass]="{'is-invalid': equipmentService('capitalEquipment')?.invalid && (equipmentService('capitalEquipment')?.touched || equipmentService('capitalEquipment')?.dirty)}"
                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Hardware Equipment Cost
                </div>
              </div>
              <div class="form-group">
                <label for="maintenance">Hardware Equipment Maintenance Cost</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="maintenance"
                         data-toggle="tooltip" data-placement="top" title="Hardware Equipment Maintenance Cost"
                         [formControl]="equipmentService('maintenance')"
                         [ngClass]="{'is-invalid': equipmentService('maintenance')?.invalid && (equipmentService('maintenance')?.touched || equipmentService('maintenance')?.dirty)}"

                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Hardware Equipment Maintenance Cost
                </div>
              </div>
              <div class="form-group">
                <label for="serviceOrLicense">Video Conferencing Service/Licensing Costs</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input type="text"
                         class="form-control"
                         id="serviceOrLicense"
                         data-toggle="tooltip" data-placement="top" title="Video Conferencing Service/Licensing Costs"
                         [formControl]="equipmentService('serviceOrLicense')"
                         [ngClass]="{'is-invalid': equipmentService('serviceOrLicense')?.invalid && (equipmentService('serviceOrLicense')?.touched || equipmentService('serviceOrLicense')?.dirty)}"
                  />
                </div>
                <div class="invalid-feedback">
                  Please provide valid value of Video Conferencing Service/Licensing Costs
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <button type="button" class="btn btn-success btn-lg" (click)="open(content)">Calculate</button>
        </div>
      </div>
      <p class="mt-2">All fields marked with * are required.</p>
    </form>


    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Savings</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="btn-group btn-group-toggle mr-2 mb-2" data-toggle="buttons">
          <label class="btn" [ngClass]="isAnnualMode() ? 'active btn-secondary' : 'btn-outline-secondary'">
            <input (change)="calculate()" type="radio" name="periodMode" id="annual" autocomplete="off" value="annual"
                   [checked]="isAnnualMode() ? 'checked' : ''" [formControl]="periodModeSwitch"> Annual
          </label>
          <label class="btn" [ngClass]="!isAnnualMode() ? 'active btn-secondary' : 'btn-outline-secondary'">
            <input (change)="calculate()" type="radio" name="periodMode" id="monthly" autocomplete="off" value="monthly"
                   [checked]="!isAnnualMode() ? 'checked' : ''" [formControl]="periodModeSwitch"> Monthly
          </label>
        </div>

        <div class="summary">
          <div class="row">
            <app-simple-summary class="printable" *ngIf="editServiceExpenses.value == false && editClinicianExpenses.value == false" [summary]="summary?.consumers" [consults]="videoCall('consults').value * (isAnnualMode() ? 12 : 1)"></app-simple-summary>
            <app-simple-and-equipment-summary class="printable" *ngIf="editServiceExpenses.value == true && editClinicianExpenses.value == false" [summary]="summary" [consults]="videoCall('consults').value * (isAnnualMode() ? 12 : 1)"></app-simple-and-equipment-summary>
            <app-middle-summary class="printable" *ngIf="editServiceExpenses.value == false && editClinicianExpenses.value == true && !isSimpleMode()" [summary]="summary" [consults]="videoCall('consults').value * (isAnnualMode() ? 12 : 1)"></app-middle-summary>
            <app-full-summary class="printable" *ngIf="editServiceExpenses.value == true && editClinicianExpenses.value == true && !isSimpleMode()" [summary]="summary" [consults]="videoCall('consults').value * (isAnnualMode() ? 12 : 1)"></app-full-summary>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="print();"><i class="bi bi-printer mr-2"></i>Print
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close')">Close</button>
      </div>
    </ng-template>


    <div class="col-md-12 pb-3">
      <div *ngIf="isSimpleMode()">
      <p>There have been assumptions made regarding some of the calculation results for this page:</p>
      <ul>
        <li>Average Patient/Family Travel (round trip): 25km</li>
        <li>Average travel and accommodation: $30</li>
        <li>Patient/Family lost productivity: $125</li>
      </ul>

      <p>You can change these numbers to reflect your service more accurately by going to <b>Full Mode</b> and inputting the desired values in column 2.</p>
      </div>

      <div *ngIf="!isSimpleMode()">
        <p>
          We have default data in all fields for this calculation. Please change the numbers to more accurately
          reflect your service, if required, using data from healthdirect Video Call platform reports and
          other appropriate data specific to your service (e.g. average patient and clinician travel times, etc…).
        </p>
      </div>

      <h4>Calculation references:</h4>
      <h6 class="my-3">These calculations are based on the latest assumptions and averages from Australian public data which may change. Please see the below references for further information:
      </h6>
      <p><b>Road Trips around Australia</b>:  25, 760 Kms of Australian Coastline is assumed. <br/>
        Reference: <a class="text-break" href="https://www.worldatlas.com/articles/countries-with-the-most-coastline.html#:~:text=Australia%20%2D%2025%2C760%20Km" target="_blank">https://www.worldatlas.com/articles/countries-with-the-most-coastline.html</a></p>
      <p><b>Fuel saved</b>: 11.1 L per 100 Km of travel is assumed for a passenger vehicle.<br/>
        Reference: 
        <a class="text-break" href="https://www.abs.gov.au/statistics/industry/tourism-and-transport/survey-motor-vehicle-use-australia/latest-release" target="_blank">https://www.abs.gov.au/statistics/industry/tourism-and-transport/survey-motor-vehicle-use-australia/latest-release
        </a></p>
      <p><b>Net CO2 emission saved</b>: Net CO2 emission is calculated by taking the difference of CO2 produced by transportation and CO2 produced using cloud computing services and endpoint devices for Video Call consultations. <br/>The average emission intensity of Australia for passenger vehicles in 2022 was 144g/km according to the latest report from National Transport Commission.<br/>
        Reference: page 47 of  
        <a class="text-break" href="https://www.ntc.gov.au/sites/default/files/assets/files/CO2%20Emissions%20Intensity%20for%20New%20Australian%20Light%20Vehicles%202022.pdf" target="_blank">https://www.ntc.gov.au/sites/default/files/assets/files/CO2%20Emissions%20Intensity%20for%20New%20Australian%20Light%20Vehicles%202022.pdf
        </a><br/>
      </p>
      <p><b>Planting trees to counter CO2 emissions</b>: 9.7 Kg per tree is assumed. CO2 consumption depends on the age and type of tree. A 5cm trunk diameter woodland eucalyptus tree stores 9.7kg equivalent CO2. (Larger trees can store more CO2).<br/>
        Reference: <a class="text-break" href="https://www.qld.gov.au/__data/assets/pdf_file/0024/68901/eucalypt-open-forests-management-guideline.pdf#page=17" target="_blank">https://www.qld.gov.au/__data/assets/pdf_file/0024/68901/eucalypt-open-forests-management-guideline.pdf</a></p>

        <p>
          For further questions regarding the video call savings calculator, please <a class="text-break" href="https://help.vcc.healthdirect.org.au/support-and-advice" target="_blank">contact us</a>.</p>
        <hr>
        <p><b>Last Updated: </b>August 2024</p>
      </div>

  </div>
</div>
