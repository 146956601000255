import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './components/header/header.component';
import {MainFormComponent} from './components/main-form/main-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SimpleSummaryComponent} from './components/summary/simple/simple-summary.component';
import {MiddleSummaryComponent} from './components/summary/middle/middle-summary.component';
import {FullSummaryComponent} from './components/summary/full/full-summary.component';
import {SimpleAndEquipmentSummaryComponent} from './components/summary/simple-and-equipment/simple-and-equipment-summary.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainFormComponent,
    SimpleSummaryComponent,
    SimpleAndEquipmentSummaryComponent,
    MiddleSummaryComponent,
    FullSummaryComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgbModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
