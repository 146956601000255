import {Component, Input} from '@angular/core';
import {Summary, SummaryPerType} from '../../main-form/main-form.component';
import { kgCo2perTree, kmPerLiterFuel, roadTripKms } from '../constants';

@Component({
  selector: 'app-middle-summary',
  templateUrl: './middle-summary.component.html',
})
export class MiddleSummaryComponent {
  @Input() summary: SummaryPerType | undefined;
  @Input() consults = 0;

  sum(one: any, two: any): number {
    return one + two;
  }

  tree(value: any): number {
    return Math.round(value / kgCo2perTree);
  }

  fuel(value: any): number {
    return value / kmPerLiterFuel;
  }

  trips(value: any): number {
    return Math.round(value / roadTripKms);
  }
}
