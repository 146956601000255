<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 841.9 536.2" style="enable-background:new 0 0 841.9 536.2;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;stroke:#1E4759;stroke-width:3.13;stroke-miterlimit:10;}
	.st1{fill:#CCECEA;}
	.st2{fill:#1E4759;}
	.st3{fill:none;stroke:#1E4759;stroke-width:0.838;stroke-miterlimit:10;}
	.st4{font-family:'OpenSans';}
	.st5{font-size:10.5338px;}
	.st6{font-size:9.1174px;}
	.st7{fill:#FFFFFF;}
	.st8{fill:#8EA3AC;}
	.st9{fill:#009F96;}
	.st10{fill:none;stroke:#FFFFFF;stroke-width:0.9595;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st11{font-size:11.3967px;}
	.st12{fill:#D2DADE;}
	.st13{fill:#204758;}
	.st14{font-size:12.7643px;}
	.st15{font-size:5.3154px;}
	.st16{font-size:7.4416px;}
	.st17{fill:#66C6C0;}
	.st18{fill:#CDD6DA;}
	.st19{fill:#4B6C7A;}
	.st20{fill:none;stroke:#FFFFFF;stroke-width:1.3676;stroke-miterlimit:10;}
	.st21{fill:#FCE9D3;}
	.st22{fill:#F6A28C;}
	.st23{fill:#F5896B;}
	.st24{font-size:6.6443px;}
	.st25{fill:#FADBB6;}
	.st26{fill:#FDF1E2;}
	.st27{fill:#FEF8F0;}
	.st28{fill:none;stroke:#FCE9D3;stroke-width:0.9984;stroke-miterlimit:10;}
	.st29{fill:none;stroke:#FCE9D3;stroke-width:1.152;stroke-miterlimit:10;}
	.st30{fill:#F9D4A7;}
	.st31{fill:none;stroke:#FFFFFF;stroke-width:2.7352;stroke-miterlimit:10;}
	.st32{fill:none;stroke:#FFFFFF;stroke-width:1.8235;stroke-miterlimit:10;}
	.st33{fill:#FDE8E2;}
	.st34{fill:none;stroke:#204758;stroke-width:1.001;stroke-linecap:round;stroke-miterlimit:10;}
	.st35{fill:#A5B5BD;}
	.st36{fill:#F6B76D;}
	.st37{fill:#E25553;}
	.st38{fill:#78919B;}
	.st39{fill:#E9EDEE;}
	.st40{fill:#F4B672;}
	.st41{font-size:7.1981px;}
	.st42{font-size:11px;}
</style>
<rect x="26.1" y="407.6" class="st0" width="205.1" height="70.2"/>
<g>
	<path class="st1" d="M147.1,409.8c0.1,0,0.6,0.1,0.7,0.5c0.1,0.4-0.2,0.2,0.8,2.1c0.2,0.4,0.8,0.9,0.6,2.1s0.2,1.8,0.9,1.9
		c0.2,0,0.1-0.9,0.6-0.6c0.4,0.2,0.9,0.7,1.2,1.3c0.1,0.1-0.1,0.5,0,0.6c0.3,0.3,0.3,1.9,0.5,2.3c0.2,0.4,1.1,0.9,1,1.5
		s-0.1,1.4,0.3,2c0.2,0.4-0.1,1.1,1.5,1.5c0.4,0.1,0.2,0.3,0.8,0.5c0.6,0.2,2.1,0.8,2,1.5c-0.1,0.6,1,1.2,0.9,1.5s0.4,0.7,0.5,1.3
		c0.1,0.5,0,1.1,0.8,0.8c0.4-0.2-0.1-0.1,0.2-0.4s0.2,0.2,0.7,0.5c0.5,0.3,0.5,0.2,0.5,0.6s-0.3,0.5-0.2,1s-0.2,0.5,0.7,0.6
		c0.3,0.1,0.3,0.4,0.5,0.6c0.4,0.3,1,0.6,1.2,1c0.4,0.6,0.5,0.8,1,1.1c0.5,0.3,0.6,0.6,0.6,1.4c0,0.6,0.4,1.1,0.2,1.9
		c-0.2,0.5,0.4,1.3,0.6,2c0.2,0.6,0.1,1.2,0.1,1.5c0,0.6-0.5,1.4-0.6,1.7s0.1,0.7-0.2,1.4s-0.2,1.8-0.5,2.7s-0.8,1.6-1.2,2
		c-0.5,0.6-1.4,1.5-1.5,2s-0.6,1.5-0.7,2.2s-0.2,1.1-0.5,1.3c-0.5,0.4-0.2,0.8-0.9,1.5c-0.6,0.6-0.1,1-0.4,1.7
		c-0.2,0.5,0,0.6,0.1,0.8s0.2,0.3,0.2,0.7s-1.5,0.7-2.3,0.8s-0.6-0.2-1.3,0c-0.6,0.2-0.9,1.5-1.9,1.9c-1.3,0.6-1.6,0.2-1.8,0
		c-0.2-0.3,0.1-0.8-0.4-1s-0.6-0.4-0.7-0.7s-0.9-0.1-1.1,0.1c-0.2,0.2,0.2,0.2-0.3,0.5c-0.5,0.3-0.4,1-1.2,1.2
		c-0.7,0.2-0.8,0-1.4-0.4c-0.6-0.4-1.8-0.3-1.9-0.7c-0.3-0.1-0.2,0.5-0.6,0.4c-0.4-0.1-0.3-0.8-0.8-0.8c-0.8,0-0.3,0.1-1.1-0.4
		c-0.4-0.3-0.3-0.5-0.5-0.8c-0.2-0.3-0.7-0.5-0.6-0.8c0.2-0.6,0.1-0.3,0-1c-0.1-0.8,0.3-0.8-0.5-1.4c-0.7-0.6-0.4-1-1.1-1
		c-0.4,0-0.7,0-0.9,0c-1.1,0.3-1.9,0-2.1-0.2c-0.4-0.5,0.5-0.6,0.8-0.3c0.2,0.2,0.6-0.1,0.7-0.2c0.3-0.3,0.9-0.1,0.9-0.2
		c0.1-0.4,0.3-0.9-0.2-1.2c-0.4-0.2-0.2-0.9-0.5-1c-0.1,0-0.6,0-0.6,0.5c0.1,0.6,0.3,1-0.1,1.2s-0.8,0.5-0.9,0.2
		c0-0.2,0-0.4,0.1-0.7c0.2-0.4,0.2-1.9,0.6-2c0.4-0.1,0.6-0.1,0.5-0.5c-0.1-0.5,0.5-1.5,0-1.7c-0.4-0.2-0.5-0.6-0.5-0.2
		c-0.1,0.5,0,0.2-0.2,0.8s-0.1-0.2-0.4,0.9c-0.1,0.5-0.3,0.6-1,0.7c-0.4,0-0.5,0.6-0.8,0.9c-0.3,0.4-0.4,0.4-0.7,0.6
		c-0.2,0.2,0.1,0.7-0.4,0.8c-0.1,0-0.3-0.2-0.4-0.6c-0.1-0.5-0.1-0.6-0.3-1s-0.7-1.1-0.8-1.7c0-0.3-0.6,0-0.9-0.1
		c-0.2-0.1-0.1-0.5-0.1-0.7c0.1-0.4-0.6-1.2-1-1.5c-0.4-0.2-0.8,0.1-1.2-0.1c-0.4-0.2-0.5-0.5-0.8-0.4c-0.1,0-0.4,0.3-0.5,0.3
		c-0.2,0-0.4-0.4-0.8-0.6c-0.7-0.4-0.4-0.6-1.1-0.6c-0.7,0,0.1,0.2-0.8,0.2s-2.7,0-3.1,0.2c-0.4,0.2-0.2,0.2-0.6,0.5
		c-0.1,0-0.6,0.3-1.1,0.4c-0.7,0.1-1.2,0.4-1.6,0.4s-1.2-0.2-1.7,0.1c-0.4,0.3-0.4,0.3-0.7,0.5c-0.1,0.1-0.3,0-0.7,0.1
		c-0.6,0.2-0.3,0.4-0.5,0.5c-0.1,0.1-0.9,0.1-1.2,0.4c-0.7,0.5-0.3,0.8-0.5,1.2s-1.9,0.6-2.8,0.6c-0.4,0-0.8,0-1.4,0
		c-0.9,0-1.8,0-1.8,0s-0.6-0.3-1,0.1c-0.2,0.2-0.4,0.2-0.5,0.5c-0.1,0.1-0.3,0.6-0.7,0.6c-1.1,0.1-0.6,0.4-1.2,0.7
		c-0.6,0.3-0.7,0.6-1.3,0.4c-0.5-0.1-1.5,0.3-2,0c-0.3-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.1-0.3-0.5-0.4c-0.2,0-0.2-0.4-0.3-0.5
		c-0.6-0.3-1-0.5-1.1-0.8c-0.1-0.4-0.3-0.4,0-0.6c0.3-0.3,0.1,0.1,0.6-0.3s0.5-0.6,0.5-1.1c-0.1-0.6-0.2-0.7,0-1.2
		c0.2-0.5,0.7-1.2,0.2-1.6c-0.7-0.6-0.7-1.5-0.8-1.9c-0.2-0.4,0-1.7-0.3-2.3c-0.3-0.6,0-1.2-0.4-1.8c-0.4-0.6-0.1-0.4-0.8-0.9
		s-0.2-1-0.5-1.6c-0.3-0.6-0.5-1.1-0.7-1.6s-0.1-0.8,0.2-0.8c0.3,0,0.1,0.7,0.6,0.4c0.4-0.3,0.7-1.2,0.2-1.6s-0.9-1.4-1.1-1.7
		c-0.3-0.3,0.1-0.2-0.3-0.7s0-0.9,0.3-1.3s0.2-3,0.4-3c0.4,0,0.3,0.6,0.7,0.7c0.5,0.1,0.5-1,1-1.2s0.9-1,1.6-1.1
		c0.7-0.2,0.9-1,1.4-1s0.9,0.2,1.6,0.2c0.2,0,0.6-0.4,0.7-0.6c0.3-0.4,1.3,0.1,1.4-0.2s0.1-0.6,0.4-0.7s1.1-0.1,1.9-0.2
		s1.3,0.1,1.6-0.4c0.2-0.2,0.9-0.6,1.1-1.3c0.3-0.9,1.2-1.3,1.4-1.4c0.4-0.3-0.3-0.6-0.3-1c0.1-0.4-0.2-0.9,0.2-1s0.6-0.2,0.8-0.4
		s0.2-0.6,0.5-0.4c0.2,0.1,0.1,0.7,0.2,0.9s0.1,0.8,0.5,0.6s0.6-0.2,0.8-0.7s-0.5-0.6-0.5-0.9c-0.1-0.3,0.4-0.4,0.6-0.4
		s0.2,0.5,0.6,0.3c0.4-0.2,1.2,0,1.1-0.3s-0.8-0.3-0.7-0.5s-0.7-0.8-0.3-0.9c0.4,0-0.1,0,0.3,0c0.4,0-0.2-0.5,0.3-0.5s0,0.5,0.6,0.4
		c0.4-0.1-0.2-0.6,0.2-0.7c0.4,0-0.1-0.5,0.4-0.9c0.5-0.4,0.2-0.1,0.7-0.1c0.4,0,0.7-0.9,1.1-0.8s0.4-0.4,1-0.4s0.9,0.4,1.1,0.6
		c0.2,0.1,0.4,0.4,0.5,0.8c0.1,0.3-0.4,0.6-0.4,0.8c0.1,0.2-0.3,0.8,0,1s0.2,0.1,0.8-0.3s0.4-0.7,0.4-0.9c0.1-0.2,0.7,0,0.9,0.2
		c0.2,0.1,0.8,0.5,1.1,0.3c0.3-0.2,0.6-0.8,0.5-1c-0.1-0.2-0.8-0.4-0.7-0.6c0.2-0.2,0.2-0.4,0.3-0.7s0.1-0.9,0.5-0.9
		s0.2-0.3,0.4-0.6c0,0-0.3-0.8,0.7-0.7c1,0,0.2-0.5,0.7-0.6c0.5-0.1,2.1,0.3,2.2,0c0.1-0.2,0.4-1.4,0.9-1.5c0.2,0,0.2-0.2,0.6,0.4
		c0.3,0.4,0.6,0.2,1,0.3s0.3,0.6,1.2,0.6s0.2-0.2,0.9-0.1s-0.2,0.3,0.5,0.3s-0.1,0.5,0.9,0.4c1,0,0.4-0.6,1.1-0.7
		c0.7,0,0.9,0.3,0.5,0.6s-1.2,1-1.4,1.1s-0.7,1.5-0.5,1.8c0.2,0.3-0.8,0.6-0.9,0.8c-0.1,0.2,0.6,1,0.6,1s0.7,0.2,0.9,0.3
		c0.2,0.1,0.1,1.3,0.7,1.1c0.4-0.2,1.2,0,1.5,0.2c0.2,0.1,0.3,0.3,0.3,0.3s0.9-0.1,0.7,0.2c-0.1,0.2,0.6,0.8,1.8,1.2
		c0.4,0.1,0.1,0.6,0.4,0.8c0.4,0.2,0.7,0.5,1.7,0.5c1,0,0.9,0,1.2-0.5s0.2-0.8,0.6-1.4c0.4-0.5,0.2-0.5,0.4-0.9
		c0.3-0.5-0.1-1.3,0.3-1.4c0.3,0,0.3-0.4,0.2-0.7s-0.7-2.8-0.1-2.9c0.4-0.1,0.1-0.5,0.1-0.8s0.5-0.3,0.5-0.5c0-0.3-0.4-0.4-0.3-0.6
		c0.1-0.2,0.1-0.5,0.4-1C146.8,410.9,146.7,409.7,147.1,409.8z"/>
	<path class="st1" d="M157.3,468.5c-0.1,0-0.9,0.3-1.1,0.3c-0.2,0-0.8,0.3-0.8,0.4s0.3,0.7,0,0.6s-0.1-0.4-0.5-0.4s-0.8,0.2-1,0
		s-0.2-0.4-0.6-0.4s-0.2-0.4-0.7-0.4s-0.1-0.4-0.6-0.4c-0.5,0-1-0.2-0.9,0c0.1,0.2-0.1,0.7,0,1c0.1,0.2,0.5,0.2,0.4,0.4
		s-0.3,0.6,0,0.7c0.4,0.1-0.1,0.6,0.3,0.6s0.5,0.3,0.5,0.4c0,0.1,0.4,0.2,0.5,0.4c0.1,0.1-0.5,0-0.5,0s-0.3,0.5,0,0.8s1.1,1,0.9,1.2
		c-0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0,0.4,0.4,0.5s0.6,0.4,0.8,0.2c0.2-0.2-0.2-0.8,0.2-0.9c0.4-0.1,0.2-0.7,0.5-0.8
		c0.3-0.1,0.6,0,0.9-0.1s0.4-0.4,0.2-0.6s-0.1-0.3,0.1-0.5c0.2-0.2-0.4-0.6,0.1-0.8c0.5-0.1,0.4-0.6,0.5-0.9s0-1,0-1.2
		C157.6,468.8,157.3,468.5,157.3,468.5z"/>
</g>
<g>
	<rect x="50.6" y="477.8" class="st2" width="12.3" height="44.8"/>
	<rect x="194.4" y="477.8" class="st2" width="12.3" height="44.8"/>
</g>
<g>
	<path class="st2" d="M66.9,401.3h-12c0-3.3,2.7-6,6-6S66.9,398,66.9,401.3z"/>
	<path class="st2" d="M115.2,401.3h-11.9c0-3.3,2.7-6,6-6S115.2,398,115.2,401.3z"/>
	<g>
		<path class="st2" d="M163.5,401.3h-12c0-3.3,2.7-6,6-6S163.5,398,163.5,401.3z"/>
		<line class="st3" x1="157.5" y1="401.3" x2="157.5" y2="407.6"/>
	</g>
	<g>
		<path class="st2" d="M202.4,401.3h-11.9c0-3.3,2.7-6,6-6S202.4,398,202.4,401.3z"/>
		<line class="st3" x1="196.4" y1="401.3" x2="196.4" y2="407.6"/>
	</g>
	<line class="st3" x1="109.2" y1="401.3" x2="109.2" y2="407.6"/>
	<line class="st3" x1="60.9" y1="401.3" x2="60.9" y2="407.6"/>
</g>
<text transform="matrix(1 0 0 1 201.5986 434.6275)" class="st2 st4 st5">{{trips(summary?.consumers?.km) | number}}</text>
<text transform="matrix(1 0 0 1 207.6113 458.7349)" class="st2 st4 st5">{{trips(summary?.clinician?.km) | number}}</text>
<g>
	<text transform="matrix(1 0 0 1 156.6501 458.8326)" class="st2 st4 st6">Clinicians</text>
	<g>
		<circle class="st2" cx="146" cy="425.4" r="3.1"/>
		<path class="st2" d="M139.9,435.6c0-3.3,2.7-6,6-6s6,2.7,6,6"/>
	</g>
	<text transform="matrix(1 0 0 1 156.6501 433.6285)" class="st2 st4 st6">Clients</text>
	<g>
		<path class="st2" d="M151.8,460.2h-11.8c-0.9,0-1.6-0.7-1.6-1.6v-7.1c0-0.9,0.7-1.6,1.6-1.6h11.8c0.9,0,1.6,0.7,1.6,1.6v7.1
			C153.5,459.5,152.8,460.2,151.8,460.2z"/>
		<g>
			<path class="st7" d="M148.1,457.9h-4.4c-0.4,0-0.7-0.3-0.7-0.7v-4.4c0-0.4,0.3-0.7,0.7-0.7h4.4c0.4,0,0.7,0.3,0.7,0.7v4.4
				C148.9,457.5,148.5,457.9,148.1,457.9z"/>
			<g>
				<path class="st2" d="M145.9,457.2L145.9,457.2c-0.3,0-0.5-0.2-0.5-0.5v-3.6c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5,0.2,0.5,0.5
					v3.6C146.4,457,146.2,457.2,145.9,457.2z"/>
				<path class="st2" d="M148.2,455L148.2,455c0,0.3-0.2,0.5-0.5,0.5h-3.6c-0.3,0-0.5-0.2-0.5-0.5l0,0c0-0.3,0.2-0.5,0.5-0.5h3.6
					C148,454.5,148.2,454.7,148.2,455z"/>
			</g>
		</g>
		<path class="st2" d="M148.7,447h-5.4c-0.4,0-0.8,0.3-0.8,0.8v4h1.1v-2.8c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5v2.8h1.1
			v-4C149.4,447.3,149.1,447,148.7,447z"/>
	</g>
</g>
<g>
	<rect x="309.6" y="186.9" class="st8" width="9" height="72.5"/>
	<path class="st9" d="M395.2,191.6H232.9c-1.1,0-1.9-0.9-1.9-1.9v-79.4c0-1.1,0.9-1.9,1.9-1.9h162.4c1.1,0,2,0.9,2,1.9v79.4
		C397.2,190.7,396.3,191.6,395.2,191.6z"/>
	<path class="st10" d="M390.4,184.8H237.7c-1.1,0-1.9-0.9-1.9-1.9V117c0-1.1,0.9-1.9,1.9-1.9h152.8c1.1,0,2,0.9,2,1.9v65.8
		C392.4,183.9,391.5,184.8,390.4,184.8z"/>
	<text transform="matrix(1 0 0 1 262.5937 152.8956)" class="st7 st4 st6">Clients</text>
	<text transform="matrix(1 0 0 1 367.0674 152.793)" class="st7 st4 st6">Km</text>
	<text transform="matrix(1 0 0 1 261.9111 176.0733)" class="st7 st4 st6">Clinicians</text>
	<text transform="matrix(1 0 0 1 367.0674 175.9708)" class="st7 st4 st6">Km</text>
	<text transform="matrix(1 0 0 1 274.3115 131.6622)" class="st7 st4 st11">TRAVEL SAVED</text>
	<g>
		<circle class="st7" cx="248" cy="145.1" r="3.1"/>
		<path class="st7" d="M241.9,155.4c0-3.3,2.7-6,6-6c3.3,0,6,2.7,6,6"/>
	</g>
	<text transform="matrix(1 0 0 1 313.7881 153.4395)" class="st7 st4 st5">{{summary?.consumers?.km | number}}</text>
	<text transform="matrix(1 0 0 1 322.6152 176.6172)" class="st7 st4 st5">{{summary?.clinician?.km | number}}</text>
	<g>
		<path class="st7" d="M253.8,176.8h-11.8c-0.9,0-1.6-0.7-1.6-1.6v-7.1c0-0.9,0.7-1.6,1.6-1.6h11.8c0.9,0,1.6,0.7,1.6,1.6v7.1
			C255.5,176.1,254.7,176.8,253.8,176.8z"/>
		<g>
			<path class="st2" d="M250.1,174.5h-4.4c-0.4,0-0.7-0.3-0.7-0.7v-4.4c0-0.4,0.3-0.7,0.7-0.7h4.4c0.4,0,0.7,0.3,0.7,0.7v4.4
				C250.9,174.2,250.5,174.5,250.1,174.5z"/>
			<g>
				<path class="st7" d="M247.9,173.9L247.9,173.9c-0.3,0-0.5-0.2-0.5-0.5v-3.6c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5,0.2,0.5,0.5
					v3.6C248.4,173.7,248.2,173.9,247.9,173.9z"/>
				<path class="st7" d="M250.2,171.6L250.2,171.6c0,0.3-0.2,0.5-0.5,0.5h-3.6c-0.3,0-0.5-0.2-0.5-0.5l0,0c0-0.3,0.2-0.5,0.5-0.5
					h3.6C249.9,171.1,250.2,171.4,250.2,171.6z"/>
			</g>
		</g>
		<path class="st7" d="M250.6,163.6h-5.4c-0.4,0-0.8,0.3-0.8,0.8v4h1.1v-2.8c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5v2.8
			h1.1v-4C251.4,163.9,251.1,163.6,250.6,163.6z"/>
	</g>
</g>
<rect x="685.1" y="214.6" class="st12" width="146.7" height="25.7"/>
<rect x="685.1" y="240.2" class="st13" width="146.7" height="41.1"/>
<text transform="matrix(1 0 0 1 692.3779 253.439)" class="st7 st4 st6">TOTAL NO. OF TRIPS SAVED</text>
<text transform="matrix(1 0 0 1 692.3779 264.379)" class="st7 st4 st6">TRAVERSING</text>
<text transform="matrix(1 0 0 1 692.3779 275.319)" class="st7 st4 st6">AUSTRALIA'S COASTLINE</text>
<text transform="matrix(1 0 0 1 692.3779 231.8463)" class="st13 st4 st14">{{sum(trips(summary?.consumers?.km), trips(summary?.clinician?.km)) | number}} TRIPS</text>
<rect x="685.1" y="455.6" class="st12" width="146.7" height="25.7"/>
<rect x="685.1" y="481.2" class="st13" width="146.7" height="41.1"/>
<text transform="matrix(1 0 0 1 692.3779 494.4351)" class="st7 st4 st6">TOTAL </text>
<text transform="matrix(1 0 0 1 692.3779 505.3751)" class="st7 st4 st6">EQUIVALENT TREES PLANTED</text>
<text transform="matrix(1 0 0 1 692.3779 472.8423)" class="st13 st4 st14">{{sum(tree(summary?.consumers?.co2), tree(summary?.clinician?.co2)) | number}} TREES</text>
<rect x="685.1" y="134.2" class="st12" width="146.7" height="25.7"/>
<rect x="685.1" y="159.9" class="st13" width="146.7" height="41.1"/>
<text transform="matrix(1 0 0 1 692.3779 173.1075)" class="st7 st4 st6">TOTAL SAVINGS IN TRAVEL, </text>
<text transform="matrix(1 0 0 1 692.3779 184.0475)" class="st7 st4 st6">ACCOMMODATION </text>
<text transform="matrix(1 0 0 1 692.3779 194.9875)" class="st7 st4 st6">&amp; LOST PRODUCTIVITY</text>
<text transform="matrix(1 0 0 1 692.3779 151.5147)" class="st13 st4 st14">{{sum(summary?.consumers?.costs, summary?.clinician?.costs) | currency:'AUD':'symbol-narrow':'4.0-2'}}</text>
<rect x="685.1" y="294.9" class="st12" width="146.7" height="25.7"/>
<rect x="685.1" y="320.6" class="st13" width="146.7" height="41.1"/>
<text transform="matrix(1 0 0 1 692.3779 333.7711)" class="st7 st4 st6">TOTAL </text>
<text transform="matrix(1 0 0 1 692.3779 344.7111)" class="st7 st4 st6">TRAVEL SAVED</text>
<text transform="matrix(1 0 0 1 692.3779 312.1773)" class="st13 st4 st14">{{sum(summary?.consumers?.km, summary?.clinician?.km) | number}} Km</text>
<rect x="685.1" y="375.2" class="st12" width="146.7" height="25.7"/>
<rect x="685.1" y="400.9" class="st13" width="146.7" height="41.1"/>
<text transform="matrix(1 0 0 1 692.3779 414.1031)" class="st7 st4 st6">NET </text>
<text transform="matrix(1 0 0 1 692.3779 425.0435)" class="st7 st4 st6">CO</text>
<text transform="matrix(1 0 0 1 705.333 428.0793)" class="st7 st4 st15">2 </text>
<text transform="matrix(1 0 0 1 709.748 425.0435)" class="st7 st4 st6">EMISSIONS SAVED</text>
<text transform="matrix(1 0 0 1 692.3779 392.5093)" class="st13 st4 st14">{{sum(summary?.consumers?.co2, summary?.clinician?.co2) | number}} Kg CO2</text>
<text transform="matrix(1 0 0 1 779.5283 396.7599)" class="st13 st4 st16"></text>
<text transform="matrix(1 0 0 1 783.7754 392.5093)" class="st13 st4 st14"> </text>
<path class="st1" d="M468.5,441.1c26.6-22.4,145.9-29,160.9,0c19.7,37.9,19.8,82.7,19.8,82.7L451.1,523
	C452,511.5,443.7,462,468.5,441.1z"/>
<circle class="st17" cx="474.7" cy="280.6" r="32.5"/>
<g>
	<circle class="st17" cx="533" cy="280.7" r="32.5"/>
	<circle class="st17" cx="571.9" cy="270.1" r="32.5"/>
	<circle class="st17" cx="593.1" cy="297.5" r="32.5"/>
	<circle class="st17" cx="554" cy="313.2" r="32.5"/>
</g>
<g>
	<path class="st18" d="M395.3,279c7.6,6.2,13.9-0.4,25.3-1.2c6-0.4,14.7-6.3,16.2-12.8c1.5-6.6,4.4-7.5,10.1-5.9
		c7.6,2.1,11.1-9.7,14.6-14.6c5-7.1-1.9-10.5,4.6-16.3c3.8-3.4-10-9.1-17.2-3.8c-2.7,2-5.5,5.6-8.3,11.2
		c-3.8,7.7-15.1,13.1-14.9,22.4c0.1,6-11.8,7.6-14.4,13.1C408.1,278.1,394.5,278.4,395.3,279z"/>
	<path class="st18" d="M475.6,153c-13.5,6.8,20.7,30.8,48.6,42.2c28,11.4,70.4,21.3,84.6,14.1c3.3-1.6,3-11.2,4.6-13.9
		c3.1-5.1,5.2-5.2,0-14c-2.7-4.6,3.1-11.1-2.3-24.5c-10-24.9-40.2-6.2-56-12.6c-15.6-6.4-27.5-19.8-41.8-12.4
		c-8,4.1-13.8,1.3-20.6,1.8C477.2,134.9,485.7,147.9,475.6,153z"/>
	<path class="st18" d="M436.8,242.9c3.8,7.1,12.3,11.1,23.6,12c3.4,0.3,7.1,0.3,11,0c15.2-1,33.4-6.2,51.6-15.4
		c38.3-19.4,61.7-49.3,52.3-66.9s-48-16.1-86.2,3.3c-19.9,10.1-35.9,23.1-45.2,35.7c-3.4,4.6,2.1,10.5-7.4,13.3
		C429.8,227,434.1,237.8,436.8,242.9z"/>
	<path class="st18" d="M444.9,178.5c-2.7,4.9,1.6,9.4-1.8,15.9c-8,15.1,25.6,34.2,56.3,44.3c20.7,6.8,47.7,19.8,58.8,6.6
		c10.4-12.5,18.7-0.9,25.2-0.9c15.8,0,14.7-10.5,18.8-11.6c14.2-3.8,1.4-25.7,0-31.2c-4.2-16.6-33-24.6-63.7-34.6
		c-24.5-8.1-44.6-18.5-63-14c-7.2,1.8-21.3,6.5-23,17.1C451.9,174.1,446.7,175.3,444.9,178.5z"/>
	<path class="st18" d="M508.2,204.4c16.5,11.1,45.6,2.5,65-19.2c9.6-10.8,42.9-30.9,24.8-43.1c-5.6-3.8-11.2,1.2-12.2-8.4
		c-1.3-13.8-27.5-19.7-33.8-11.8c-6.2,7.7-16-7.9-21.9,9c-2.3,6.6-11.6,8.5-16.6,14.1C494.1,166.8,491.7,193.4,508.2,204.4z"/>
	<path class="st18" d="M466.7,186.9c-13.6,10.7-9.9,34.7,8.3,53.6c5.2,5.4,15.3,4.8,17,13.1c1.6,7.7,9.7,3.9,16.2,4.5
		c7.7,0.7,18.8,2.3,20-4.2c1.5-7.6,9.2-3.8,10.5-7.1c4.9-12.2-0.4-30.2-14.7-45C506,182.9,480.3,176.2,466.7,186.9z"/>
</g>
<path class="st19" d="M161.5,208c8,3.1,22.2,9.7,54.5,29.7c19.5,12.1,28.6,13.3,51.3,29.5c16.3,11.7,93.1-0.4,110.4,18
	c25.3,26.9,45.9,106.1,48.4,117.1c7.2,32,14.9,32.5,30.8,57.6c14.6,23.1,19.4,63,19.4,63H242.7c0,0,28.9-18.2,72.5-46.4
	c18.5-12,36.8-38.2,36.7-55.4c-0.2-26.6-12.6-42.5-29.2-103.7c-10.4-38-70.3-28.5-96.4-45.5c-32.1-20.9-73.7-41.2-74-43.8
	C149.6,208.4,161.5,208,161.5,208z"/>
<path class="st20" d="M220.4,250.7c-2.8-1.8-20.5-13.5-28.2-16.3"/>
<rect x="18.3" y="206" class="st21" width="177" height="121.2"/>
<path class="st22" d="M553.2,436h-33.9c-0.1,0-0.2-0.1-0.2-0.2l10.2-131.6c0-0.1,0.1-0.2,0.2-0.2h13.3c0.1,0,0.2,0.1,0.2,0.2
	L553.2,436z"/>
<circle class="st17" cx="511.8" cy="270.1" r="32.5"/>
<circle class="st17" cx="533.5" cy="332.4" r="32.5"/>
<circle class="st17" cx="495.7" cy="309.3" r="32.5"/>
<g>
	<path class="st23" d="M598,458.2h-17.1c-0.1,0-0.1-0.1-0.1-0.2l5.1-113.2c0-0.1,0-0.2,0.1-0.2h6.7c0.1,0,0.1,0.1,0.1,0.2L598,458.2
		z"/>
	<path class="st9" d="M552.8,319.6C552.8,319.6,552.8,319.6,552.8,319.6c-0.9-2.6-1.4-5.3-1.2-8.1c1-13.1,15.8-22.5,33.2-21
		c9.8,0.8,18.3,4.9,23.7,10.7c9.1-1.8,17.7,0.5,22.7,7c4.8,6.2,5,14.9,1.4,23.2c3.2,1.2,5.9,3.2,8,6c7.9,10.4,3.2,27.6-10.5,38.4
		c-12.2,9.6-27.5,11-36.3,3.8c-11.9,5.5-24.5,4.1-30.9-4.3c-2.1-2.8-3.3-6.1-3.7-9.6c-6.9,0-13-2.5-16.9-7.5
		C534.3,347.6,539.1,330.4,552.8,319.6z"/>
</g>
<g>
	<path class="st23" d="M490.7,456.2h-17.1c-0.1,0-0.1-0.1-0.1-0.2l5.1-113.2c0-0.1,0-0.2,0.1-0.2h6.7c0.1,0,0.1,0.1,0.1,0.2
		L490.7,456.2z"/>
	<path class="st9" d="M518,319.2C517.9,319.2,517.9,319.2,518,319.2c1-2.6,1.4-5.4,1.2-8.2c-1.2-13.2-17-22.9-35.5-21.6
		c-10.4,0.8-19.4,4.8-25.1,10.6c-9.7-1.9-18.9,0.4-24,6.9c-5,6.3-5.1,15-1.3,23.4c-3.4,1.2-6.3,3.2-8.5,6
		c-8.3,10.4-3.2,27.9,11.5,38.9c13.1,9.9,29.3,11.3,38.6,4.1c12.6,5.6,26.1,4.3,32.8-4.1c2.2-2.8,3.5-6.1,3.9-9.7
		c7.3,0.1,13.8-2.4,17.9-7.5C537.8,347.7,532.7,330.3,518,319.2z"/>
</g>
<g>
	<g>
		<circle class="st7" cx="457.9" cy="311.3" r="3.1"/>
		<path class="st7" d="M451.8,321.5c0-3.3,2.7-6,6-6s6,2.7,6,6"/>
	</g>
	<text transform="matrix(1 0 0 1 469.0605 317.5543)" class="st7 st4 st6">Clients</text>
</g>
<text transform="matrix(1 0 0 1 461.1767 351.439)" class="st7 st4 st5">{{tree(summary?.consumers?.co2) | number}}</text>
<text transform="matrix(1 0 0 1 467.1074 277.066)" class="st7 st4 st11">THAT’S EQUIVALENT TO</text>
<text transform="matrix(1 0 0 1 461.2539 366.5972)" class="st7 st4 st11">TREES</text>
<text transform="matrix(1 0 0 1 575.1133 365.9263)" class="st7 st4 st11">TREES</text>
<text transform="matrix(1 0 0 1 578.043 350.7711)" class="st7 st4 st5">{{tree(summary?.clinician?.co2) | number}}</text>
<g>
	<text transform="matrix(1 0 0 1 580.8721 317.8775)" class="st7 st4 st6">Clinicians</text>
	<g>
		<path class="st7" d="M575.5,319.9h-11.8c-0.9,0-1.6-0.7-1.6-1.6v-7.1c0-0.9,0.7-1.6,1.6-1.6h11.8c0.9,0,1.6,0.7,1.6,1.6v7.1
			C577.2,319.2,576.4,319.9,575.5,319.9z"/>
		<g>
			<path class="st2" d="M571.8,317.6h-4.3c-0.4,0-0.7-0.3-0.7-0.7v-4.4c0-0.4,0.3-0.7,0.7-0.7h4.3c0.4,0,0.7,0.3,0.7,0.7v4.4
				C572.5,317.2,572.2,317.6,571.8,317.6z"/>
			<g>
				<path class="st7" d="M569.6,316.9L569.6,316.9c-0.3,0-0.5-0.2-0.5-0.5v-3.6c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5,0.2,0.5,0.5
					v3.6C570.1,316.7,569.9,316.9,569.6,316.9z"/>
				<path class="st7" d="M571.9,314.7L571.9,314.7c0,0.3-0.2,0.5-0.5,0.5h-3.6c-0.3,0-0.5-0.2-0.5-0.5l0,0c0-0.3,0.2-0.5,0.5-0.5
					h3.6C571.7,314.2,571.9,314.4,571.9,314.7z"/>
			</g>
		</g>
		<path class="st7" d="M572.3,306.7H567c-0.4,0-0.8,0.3-0.8,0.8v4h1v-2.8c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5v2.8h1v-4
			C573.1,307,572.8,306.7,572.3,306.7z"/>
	</g>
</g>
<text transform="matrix(1 0 0 1 466.1328 173.8116)" class="st2 st4 st11">CO</text>
<text transform="matrix(1 0 0 1 482.3262 177.6066)" class="st2 st4 st24">2 </text>
<text transform="matrix(1 0 0 1 487.8447 173.8116)" class="st2 st4 st11">EMISSIONS SAVED</text>
<g>
	<text transform="matrix(1 0 0 1 562.8965 197.0176)" class="st2 st4 st6">Kg CO</text>
	<text transform="matrix(1 0 0 1 589.1182 200.0539)" class="st2 st4 st15">2</text>
	<text transform="matrix(1 0 0 1 592.1523 197.0176)" class="st2 st4 st6"> </text>
	<text transform="matrix(1 0 0 1 476.8496 223.0416)" class="st2 st4 st6">Clinicians</text>
	<text transform="matrix(1 0 0 1 562.8965 223.0406)" class="st2 st4 st6">Kg CO</text>
	<text transform="matrix(1 0 0 1 589.1182 226.0764)" class="st2 st4 st15">2</text>
	<text transform="matrix(1 0 0 1 592.1523 223.0406)" class="st2 st4 st6"> </text>
	<g>
		<circle class="st2" cx="467.9" cy="190.1" r="3.1"/>
		<path class="st2" d="M461.9,200.3c0-3.3,2.7-6,6-6c3.3,0,6,2.7,6,6"/>
	</g>
	<text transform="matrix(1 0 0 1 476.8496 197.0176)" class="st2 st4 st6">Clients</text>
	<text transform="matrix(1 0 0 1 520.2461 197.1866)" class="st2 st4 st5">{{summary?.consumers?.co2 | number:'1.0-0'}}</text>
	<text transform="matrix(1 0 0 1 526.2588 223.2095)" class="st2 st4 st5">{{summary?.clinician?.co2 | number:'1.0-0'}}</text>
	<g>
		<path class="st2" d="M472,225.1h-11.8c-0.9,0-1.6-0.7-1.6-1.6v-7.1c0-0.9,0.7-1.6,1.6-1.6H472c0.9,0,1.6,0.7,1.6,1.6v7.1
			C473.6,224.3,472.9,225.1,472,225.1z"/>
		<g>
			<path class="st7" d="M468.2,222.7h-4.4c-0.4,0-0.7-0.3-0.7-0.7v-4.4c0-0.4,0.3-0.7,0.7-0.7h4.4c0.4,0,0.7,0.3,0.7,0.7v4.4
				C469,222.4,468.6,222.7,468.2,222.7z"/>
			<g>
				<path class="st2" d="M466,222.1L466,222.1c-0.3,0-0.5-0.2-0.5-0.5V218c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5,0.2,0.5,0.5v3.6
					C466.5,221.9,466.3,222.1,466,222.1z"/>
				<path class="st2" d="M468.3,219.8L468.3,219.8c0,0.3-0.2,0.5-0.5,0.5h-3.6c-0.3,0-0.5-0.2-0.5-0.5l0,0c0-0.3,0.2-0.5,0.5-0.5
					h3.6C468.1,219.4,468.3,219.6,468.3,219.8z"/>
			</g>
		</g>
		<path class="st2" d="M468.8,211.8h-5.4c-0.4,0-0.8,0.3-0.8,0.8v4h1v-2.8c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5v2.8h1
			v-4C469.5,212.2,469.2,211.8,468.8,211.8z"/>
	</g>
</g>
<rect x="14.1" y="193.1" class="st25" width="185.3" height="13.1"/>
<polygon class="st26" points="106.8,158.6 14.1,187.4 199.4,187.4 "/>
<rect x="23.7" y="187.6" class="st26" width="166.1" height="5.3"/>
<polygon class="st27" points="106.8,165.1 37.3,186.7 176.3,186.7 "/>
<g>
	<g>
		<rect x="24.5" y="298.3" class="st7" width="14.8" height="24.2"/>
		<rect x="43.1" y="298.3" class="st7" width="14.8" height="24.2"/>
	</g>
	<line class="st28" x1="50.5" y1="298.3" x2="50.5" y2="322.5"/>
	<line class="st28" x1="31.9" y1="298.3" x2="31.9" y2="322.5"/>
	<line class="st29" x1="57.9" y1="306.4" x2="24.5" y2="306.4"/>
</g>
<g>
	<g>
		<rect x="155.7" y="298.3" class="st7" width="14.8" height="24.2"/>
		<rect x="174.2" y="298.3" class="st7" width="14.8" height="24.2"/>
	</g>
	<line class="st28" x1="181.6" y1="298.3" x2="181.6" y2="322.5"/>
	<line class="st28" x1="163.1" y1="298.3" x2="163.1" y2="322.5"/>
	<line class="st29" x1="189.1" y1="306.4" x2="155.7" y2="306.4"/>
</g>
<g>
	<rect x="21.3" y="213.4" class="st30" width="14.2" height="112"/>
	<rect x="16.9" y="206" class="st30" width="22.9" height="5.1"/>
	<rect x="19.4" y="211.1" class="st30" width="18" height="4.5"/>
	<rect x="16.9" y="329.8" class="st30" width="22.9" height="5.1"/>
	<rect x="19.4" y="325.4" class="st30" width="18" height="4.5"/>
</g>
<g>
	<rect x="177.5" y="213.4" class="st30" width="14.2" height="112"/>
	<rect x="173.2" y="206" class="st30" width="22.9" height="5.1"/>
	<rect x="175.6" y="211.1" class="st30" width="18" height="4.5"/>
	<rect x="173.1" y="329.8" class="st30" width="22.9" height="5.1"/>
	<rect x="175.6" y="325.4" class="st30" width="18" height="4.5"/>
</g>
<g>
	<g>
		<g>
			<path class="st23" d="M625.9,469.9h-5.1c0,0,0,0,0,0l1.5-33.5c0,0,0,0,0,0h2c0,0,0,0,0,0L625.9,469.9z"/>
			<path class="st9" d="M634.9,424.3C634.9,424.3,634.9,424.2,634.9,424.3c0.2-0.9,0.2-1.8,0-2.8c-1-4.3-6.6-6.6-12.4-5.2
				c-3.3,0.8-5.9,2.6-7.4,4.8c-3.2-0.1-6,1.1-7.3,3.5c-1.3,2.3-0.9,5.2,0.8,7.8c-1,0.6-1.8,1.4-2.4,2.4c-2.1,3.9,0.4,9.3,5.6,12.2
				c4.7,2.6,9.9,2.2,12.5-0.6c4.3,1.2,8.5,0.1,10.2-3.1c0.6-1,0.8-2.2,0.7-3.4c2.3-0.4,4.3-1.5,5.3-3.4
				C642.6,432.6,640.1,427.1,634.9,424.3z"/>
		</g>
		<g>
			<path class="st23" d="M501.1,461.2H496c0,0,0,0,0,0l1.5-33.5c0,0,0,0,0,0h2c0,0,0,0,0,0L501.1,461.2z"/>
			<path class="st9" d="M511.1,409.5C511.1,409.5,511.1,409.5,511.1,409.5c0.2-1,0.2-2,0-3c-1.1-4.6-7.1-7.1-13.3-5.6
				c-3.5,0.9-6.4,2.8-8,5.2c-3.4-0.1-6.4,1.2-7.9,3.8c-1.4,2.5-0.9,5.6,0.8,8.4c-1.1,0.6-2,1.5-2.6,2.6c-2.3,4.2,0.4,10,6.1,13.1
				c5,2.8,10.7,2.4,13.5-0.7c4.6,1.3,9.2,0.1,11-3.3c0.6-1.1,0.9-2.4,0.8-3.7c2.5-0.4,4.6-1.6,5.7-3.7
				C519.5,418.5,516.8,412.6,511.1,409.5z"/>
		</g>
		<g>
			<path class="st23" d="M549.7,523.5h-5.1c0,0,0,0,0,0l1.5-33.5c0,0,0,0,0,0h2c0,0,0,0,0,0L549.7,523.5z"/>
			<path class="st9" d="M557.6,480.3C557.6,480.3,557.6,480.3,557.6,480.3c0.1-0.8,0.2-1.6,0-2.5c-0.9-3.8-5.8-5.9-11-4.6
				c-2.9,0.7-5.3,2.3-6.6,4.3c-2.8-0.1-5.3,1-6.5,3.1c-1.1,2.1-0.8,4.6,0.7,6.9c-0.9,0.5-1.6,1.2-2.1,2.1c-1.9,3.4,0.3,8.3,5,10.8
				c4.1,2.3,8.8,2,11.1-0.6c3.8,1,7.5,0,9.1-2.7c0.5-0.9,0.7-2,0.7-3c2.1-0.3,3.8-1.3,4.7-3C564.5,487.7,562.2,482.9,557.6,480.3z"
				/>
		</g>
		<g>
			<path class="st23" d="M574,498.3H569c0,0,0,0,0,0l1.5-33.5c0,0,0,0,0,0h2c0,0,0,0,0,0L574,498.3z"/>
			<path class="st9" d="M583.5,448C583.5,448,583.5,448,583.5,448c0.2-0.9,0.2-1.9,0-2.8c-1.1-4.4-6.7-6.8-12.7-5.4
				c-3.4,0.8-6.1,2.7-7.6,4.9c-3.3-0.1-6.1,1.1-7.5,3.6c-1.3,2.4-0.9,5.3,0.8,8c-1,0.6-1.9,1.4-2.4,2.5c-2.2,4,0.4,9.5,5.8,12.5
				c4.8,2.6,10.1,2.2,12.8-0.7c4.4,1.2,8.7,0.1,10.5-3.1c0.6-1.1,0.8-2.3,0.8-3.5c2.4-0.4,4.4-1.5,5.4-3.5
				C591.4,456.5,588.8,450.9,583.5,448z"/>
		</g>
		<g>
			<path class="st23" d="M525.5,503.3h-5.1c0,0,0,0,0,0l1.5-33.5c0,0,0,0,0,0h2c0,0,0,0,0,0L525.5,503.3z"/>
			<path class="st9" d="M534,453C534,453,534,453,534,453c0.2-0.9,0.2-1.9,0-2.8c-1.1-4.4-6.7-6.8-12.7-5.4
				c-3.4,0.8-6.1,2.7-7.6,4.9c-3.3-0.1-6.1,1.1-7.5,3.6c-1.3,2.4-0.9,5.3,0.8,8c-1,0.6-1.9,1.4-2.4,2.5c-2.2,4,0.4,9.5,5.8,12.5
				c4.8,2.6,10.1,2.2,12.8-0.7c4.4,1.2,8.7,0.1,10.5-3.1c0.6-1.1,0.8-2.3,0.8-3.5c2.4-0.4,4.4-1.5,5.4-3.5
				C541.9,461.5,539.3,455.9,534,453z"/>
		</g>
	</g>
	<g>
		<path class="st23" d="M605.6,515.2h-5.1c0,0,0,0,0,0l1.5-33.5c0,0,0,0,0,0h2c0,0,0,0,0,0L605.6,515.2z"/>
		<path class="st9" d="M612.2,471C612.2,471,612.1,471,612.2,471c0.1-0.7,0.1-1.4,0-2.1c-0.8-3.3-5.1-5.1-9.5-4
			c-2.5,0.6-4.6,2-5.7,3.7c-2.5-0.1-4.6,0.9-5.6,2.7c-1,1.8-0.7,4,0.6,6c-0.8,0.5-1.4,1.1-1.8,1.9c-1.6,3,0.3,7.2,4.3,9.4
			c3.6,2,7.6,1.7,9.6-0.5c3.3,0.9,6.6,0,7.9-2.4c0.4-0.8,0.6-1.7,0.6-2.6c1.8-0.3,3.3-1.2,4.1-2.6
			C618.1,477.5,616.2,473.2,612.2,471z"/>
	</g>
	<g>
		<path class="st23" d="M494.4,515.2h-5.1c0,0,0,0,0,0l1.5-33.5c0,0,0,0,0,0h2c0,0,0,0,0,0L494.4,515.2z"/>
		<path class="st9" d="M500.9,471C500.9,471,500.9,471,500.9,471c0.1-0.7,0.1-1.4,0-2.1c-0.8-3.3-5.1-5.1-9.5-4
			c-2.5,0.6-4.6,2-5.7,3.7c-2.5-0.1-4.6,0.9-5.6,2.7c-1,1.8-0.7,4,0.6,6c-0.8,0.5-1.4,1.1-1.8,1.9c-1.6,3,0.3,7.2,4.3,9.4
			c3.6,2,7.6,1.7,9.6-0.5c3.3,0.9,6.6,0,7.9-2.4c0.4-0.8,0.6-1.7,0.6-2.6c1.8-0.3,3.3-1.2,4.1-2.6
			C506.9,477.5,504.9,473.2,500.9,471z"/>
	</g>
</g>
<path class="st31" d="M371.2,512.3c8.4-17.6,12.6-32.3,12.9-38"/>
<path class="st31" d="M389.2,447.5c2.2-14.6,3.4-22.1-0.2-38"/>
<path class="st31" d="M386.6,384.3c-2.4-11.1-7.5-28.8-11.9-37.8"/>
<path class="st31" d="M367,327.6c-1.7-9.4-10.5-22.4-17.3-26.7"/>
<path class="st32" d="M334.8,287.5c-5-2-28.3-3.9-34.9-5.4"/>
<path class="st32" d="M274.7,277.1c-12.5-1.5-35-14.9-33-13.8"/>
<g>
	<text transform="matrix(1 0 0 1 57.2685 268.6861)" class="st2 st4 st6">Clients</text>
	<text transform="matrix(1 0 0 1 57.2685 288.6216)" class="st2 st4 st6">Clinicians</text>
	<g>
		<circle class="st2" cx="45.5" cy="261.5" r="3.1"/>
		<path class="st2" d="M39.5,271.8c0-3.3,2.7-6,6-6c3.3,0,6,2.7,6,6"/>
	</g>
	<text transform="matrix(1 0 0 1 115.6748 267.98)" class="st2 st4 st5">{{summary?.consumers?.costs | currency:'AUD':'symbol-narrow':'4.0-2'}}</text>
	<text transform="matrix(1 0 0 1 121.6875 287.0738)" class="st2 st4 st5">{{summary?.clinician?.costs | currency:'AUD':'symbol-narrow':'4.0-2'}}</text>
	<g>
		<path class="st2" d="M51.4,291.7H39.7c-0.9,0-1.6-0.7-1.6-1.6v-7.1c0-0.9,0.7-1.6,1.6-1.6h11.8c0.9,0,1.6,0.7,1.6,1.6v7.1
			C53.1,291,52.3,291.7,51.4,291.7z"/>
		<g>
			<path class="st7" d="M47.7,289.4h-4.3c-0.4,0-0.7-0.3-0.7-0.7v-4.4c0-0.4,0.3-0.7,0.7-0.7h4.3c0.4,0,0.7,0.3,0.7,0.7v4.4
				C48.4,289.1,48.1,289.4,47.7,289.4z"/>
			<g>
				<path class="st2" d="M45.5,288.8L45.5,288.8c-0.3,0-0.5-0.2-0.5-0.5v-3.6c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5,0.2,0.5,0.5v3.6
					C46,288.6,45.7,288.8,45.5,288.8z"/>
				<path class="st2" d="M47.7,286.5L47.7,286.5c0,0.3-0.2,0.5-0.5,0.5h-3.6c-0.3,0-0.5-0.2-0.5-0.5l0,0c0-0.3,0.2-0.5,0.5-0.5h3.6
					C47.5,286,47.7,286.3,47.7,286.5z"/>
			</g>
		</g>
		<path class="st2" d="M48.2,278.5h-5.4c-0.4,0-0.8,0.3-0.8,0.8v4h1v-2.8c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5v2.8h1v-4
			C49,278.8,48.6,278.5,48.2,278.5z"/>
	</g>
</g>
<g>
	<path class="st33" d="M335.4,400.5h-89.2v-81c0-8,6.5-14.5,14.5-14.5h60.1c8,0,14.5,6.5,14.5,14.5L335.4,400.5L335.4,400.5z"/>
	<g>
		<line class="st34" x1="299" y1="314.2" x2="299" y2="321.4"/>
		<line class="st34" x1="303.3" y1="321.4" x2="299" y2="321.4"/>
		<path class="st34" d="M303.3,318.4v13.5c0,1.4-1.7,3.4-4,3.4h-5.1"/>
		<line class="st34" x1="297.9" y1="313.2" x2="303.3" y2="318.4"/>
		<path class="st35" d="M297.6,341.9h-19.2v-29.8c0-1,0.8-1.8,1.8-1.8h15.7c1,0,1.8,0.8,1.8,1.8L297.6,341.9L297.6,341.9z"/>
		<path class="st13" d="M292.4,322.9h-8.6c-0.6,0-1-0.5-1-1v-6.7c0-0.6,0.5-1,1-1h8.6c0.6,0,1,0.5,1,1v6.7
			C293.4,322.4,293,322.9,292.4,322.9z"/>
	</g>
	<text transform="matrix(1 0 0 1 251.9287 361.6558)" class="st2 st4 st11">FUEL SAVINGS</text>
	<text transform="matrix(1 0 0 1 273.9179 382.1314)" class="st2 st4 st5">{{fuel(sum(summary?.consumers?.km, summary?.clinician?.km)) | number}}L</text>
	<text transform="matrix(1 0 0 1 305.0966 381.732)" class="st2 st4 st6"></text>
</g>
<g>
	<path class="st36" d="M393.4,275.5l0.4,0c0.8,0,1.4-0.7,1.3-1.5l-0.1-3.2c0-0.8-0.7-1.4-1.5-1.3l-0.4,0c-0.8,0-1.4,0.7-1.3,1.5
		l0.1,3.2C391.9,274.9,392.6,275.5,393.4,275.5z"/>
	<path class="st2" d="M378.1,248.5c-1.3-0.5-29.7,0.6-31.2,1.4c-2.2,1.2-12.6,15.6-11.7,15.7c1.8,0.2,51.4-1.8,52.8-2.3
		C389.4,262.9,379.4,249.1,378.1,248.5z"/>
	<path class="st37" d="M315.9,282.8l73.9-3.1c4.7-0.2,3.6-4,3.5-8.6l0,0c-0.2-4.7-2.5-8.4-8.8-8.1c-6.3,0.3-48.4,2-48.4,2
		c0.6-1.6,1.2-3.7-0.2-3.1c-1.4,0.6-7.4,0.7-15.8,3.3c-5.5,1.7-5.8,2.8-8.5,8.7l0,1C311.9,279.6,311.2,283,315.9,282.8z"/>
	<path class="st35" d="M311.4,284.8l82.7-3.5c0.7,0,1.2-0.6,1.2-1.3l0,0c0-0.7-0.4-2.8-2-2.7c-2.6,0.1-3.6,1.7-3.6,1.7l-72.1,3
		c0,0-0.7-2.1-6.4-1.8c-1.2,0-1.1,2.7-1.1,3.4l0,0C310.1,284.3,310.7,284.8,311.4,284.8z"/>
	<circle class="st2" cx="377.3" cy="282" r="8.8"/>
	<circle class="st35" cx="377.3" cy="282" r="5.5"/>
	<circle class="st2" cx="328" cy="283.9" r="8.8"/>
	<circle class="st35" cx="328" cy="283.9" r="5.5"/>
	<path class="st38" d="M376,252.1c-0.8,0-12.9,0-12.7,0.5s0.7,9.4,0.4,9.8s17.3-0.5,18.2-0.6C382.8,261.7,377.1,252,376,252.1z"/>
	<path class="st38" d="M348,253c0.8,0,11.4-0.8,11.4-0.2c0,1.1,0.4,8.2,0.4,9.8c0,0.4-17.8,0.9-18.7,0.9
		C340.2,263.5,346.9,253,348,253z"/>
	<path class="st38" d="M344.6,252.2c-0.8,0.3-8.8,9.8-8.4,10.6s-1,2.8,0.6,1.9c1.3-0.6,9.1-11.2,9.1-11.8
		C345.9,252.4,344.6,252.2,344.6,252.2z"/>
	<path class="st23" d="M392.7,275.5l0.2,0c0.4,0,0.7-0.4,0.7-0.8l-0.2-4.6c0-0.4-0.4-0.7-0.8-0.7l-0.2,0c-0.4,0-0.7,0.4-0.7,0.8
		l0.2,4.6C391.9,275.2,392.3,275.5,392.7,275.5z"/>
	<path class="st36" d="M311.4,278.4l-0.5,0c-0.6,0-1.1-0.5-1.1-1l-0.1-2.2c0-0.6,0.5-1.1,1-1.1l0.5,0c0.6,0,1.1,0.5,1.1,1l0.1,2.2
		C312.5,277.8,312,278.3,311.4,278.4z"/>
	<path class="st23" d="M312,278.3l-0.2,0c-0.3,0-0.6-0.2-0.6-0.5l-0.1-3.2c0-0.3,0.2-0.6,0.5-0.6l0.2,0c0.3,0,0.6,0.2,0.6,0.5
		l0.1,3.2C312.5,278.1,312.3,278.3,312,278.3z"/>
</g>
<g>
	<text transform="matrix(1 0 0 1 51.7256 223.9488)" class="st2 st4 st11">SAVINGS IN TRAVEL, </text>
	<text transform="matrix(1 0 0 1 58.2156 236.2588)" class="st2 st4 st11">ACCOMODATION  </text>
	<text transform="matrix(1 0 0 1 45.1656 248.5688)" class="st2 st4 st11">&amp; LOST PRODUCTIVITY</text>
</g>
<g>
	<rect x="91.2" y="304.8" class="st12" width="14.8" height="22.4"/>
	<rect x="107.5" y="304.8" class="st12" width="14.8" height="22.4"/>
</g>
<g>
	<g>
		<circle class="st39" cx="138.6" cy="62.2" r="32.5"/>
		<circle class="st39" cx="178" cy="53.7" r="32.5"/>
		<circle class="st39" cx="198.6" cy="78.9" r="32.5"/>
		<circle class="st39" cx="159.5" cy="94.7" r="32.5"/>
	</g>
	<circle class="st40" cx="90.9" cy="78.4" r="59.3"/>
	<g>
		<g>
			<g>
				<text transform="matrix(1 0 0 1 45.6465 81.3087)" class="st2 st4 st11">TOTAL NUMBER </text>
				<text transform="matrix(1 0 0 1 52.5665 94.9887)" class="st2 st4 st11">OF CONSULTS</text>
				<text transform="matrix(1 0 0 1 81.915 114.4014)" class="st2 st4 st5">{{consults | number}}</text>
			</g>
			<path class="st13" d="M95.7,38.3c-1,0-1.8,0.8-1.8,1.8c0,0.1,0,0.1,0,0.2c0.1,0.9,0.9,1.7,1.8,1.7c0.9,0,1.7-0.7,1.8-1.7
				c0-0.1,0-0.1,0-0.2C97.6,39.1,96.8,38.3,95.7,38.3"/>
		</g>
		<g>
			<path class="st13" d="M89.8,57.2h11.9c-1.3-1.8-3.5-3-5.9-3C93.3,54.2,91.2,55.3,89.8,57.2"/>
			<path class="st13" d="M91.5,48.7c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2
				C93.4,44.5,91.5,46.4,91.5,48.7"/>
			<path class="st13" d="M83.6,42.1h9.9c-0.4-0.5-0.7-1.1-0.8-1.8h-9.3c-0.9,0-1.7,0.8-1.7,1.7v1.4c0.6,0,1.2,0.1,1.8,0.3L83.6,42.1
				L83.6,42.1z"/>
			<path class="st13" d="M108,40.3h-9.3c-0.1,0.7-0.3,1.3-0.8,1.8h9.9v16.1H84.8c1.1,0.4,2.2,1,3.2,1.8h3.6h8.2h8.1
				c0.9,0,1.7-0.8,1.7-1.7V42C109.6,41.1,108.9,40.3,108,40.3"/>
			<path class="st13" d="M86.5,60c-1.4-0.9-2.9-1.4-4.6-1.4c-0.1,0-0.3,0-0.4,0c-3.8,0-7.1,2.3-9.2,5.9h18.4
				C89.6,62.6,88.1,61,86.5,60"/>
			<path class="st13" d="M83.6,44.7c-0.6-0.2-1.2-0.3-1.8-0.4c-0.1,0-0.3,0-0.4,0c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5
				c0.1,0,0.3,0,0.4,0c0.6,0,1.2-0.2,1.8-0.4c2.5-0.9,4.4-3.3,4.4-6.2C88,48,86.2,45.6,83.6,44.7"/>
		</g>
	</g>
</g>
<text transform="matrix(1 0 0 1 449.2256 336.2974)" class="st7 st4 st11">PLANTING</text>
<text transform="matrix(1 0 0 1 563.085 335.6275)" class="st7 st4 st11">PLANTING</text>
<text transform="matrix(1 0 0 1 96.9013 180.2491)" class="st13 st4 st41">BANK</text>
<text transform="matrix(1 0 0 1 31.9149 426.7)"><tspan x="0" y="0" class="st2 st4 st42">NO. OF TRIPS  </tspan><tspan x="0" y="13.2" class="st2 st4 st42">SAVED TRAVERSING</tspan><tspan x="0" y="26.4" class="st2 st4 st42">AUSTRALIA’S  </tspan><tspan x="0" y="39.6" class="st2 st4 st42">COASTLINE</tspan></text>
</svg>
