import {Component, Input} from '@angular/core';
import {Summary, SummaryPerType} from '../../main-form/main-form.component';
import { kgCo2perTree, kmPerLiterFuel, roadTripKms } from '../constants';

@Component({
  selector: 'app-simple-and-equipment-summary',
  templateUrl: './simple-and-equipment-summary.component.html',
})
export class SimpleAndEquipmentSummaryComponent {
  @Input() summary: SummaryPerType | undefined;
  @Input() consults = 0;

  tree(value: any): number {
    return Math.round(value / kgCo2perTree);
  }

  fuel(value: any): number {
    return value / kmPerLiterFuel;
  }

  trips(value: any): number {
    return Math.round(value / roadTripKms);
  }
}
