<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.89 536.16">
<style type="text/css">
	.st0 {
    fill: #FFFFFF;
    stroke: #1E4759;
    stroke-width: 3.13;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: #CCECEA;
  }

  .st2 {
    fill: #1E4759;
  }

  .st3 {
    fill: none;
    stroke: #1E4759;
    stroke-width: 0.838;
    stroke-miterlimit: 10;
  }

  .st4 {
    fill: #FFFFFF;
  }

  .st5 {
    fill: #8EA3AC;
  }

  .st6 {
    fill: #009F96;
  }

  .st7 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 0.9595;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st8 {
    fill: #E8EDEE;
  }

  .st9 {
    fill: #F48A6F;
  }

  .st10 {
    fill: #F4B672;
  }

  .st11 {
    fill: #8FA3AB;
  }

  .st12 {
    fill: #204758;
  }

  .st13 {
    fill: #D2DADE;
  }

  .st14 {
    fill: #66C6C0;
  }

  .st15 {
    fill: #CDD6DA;
  }

  .st16 {
    fill: #4B6C7A;
  }

  .st17 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 1.3676;
    stroke-miterlimit: 10;
  }

  .st18 {
    fill: #FCE9D3;
  }

  .st19 {
    fill: #F6A28C;
  }

  .st20 {
    fill: #F5896B;
  }

  .st21 {
    fill: #FADBB6;
  }

  .st22 {
    fill: #FDF1E2;
  }

  .st23 {
    fill: #FEF8F0;
  }

  .st24 {
    fill: none;
    stroke: #FCE9D3;
    stroke-width: 0.9984;
    stroke-miterlimit: 10;
  }

  .st25 {
    fill: none;
    stroke: #FCE9D3;
    stroke-width: 1.152;
    stroke-miterlimit: 10;
  }

  .st26 {
    fill: #F9D4A7;
  }

  .st27 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2.7352;
    stroke-miterlimit: 10;
  }

  .st28 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 1.8235;
    stroke-miterlimit: 10;
  }

  .st29 {
    fill: #FDE8E2;
  }

  .st30 {
    fill: none;
    stroke: #204758;
    stroke-width: 1.001;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st31 {
    fill: #A5B5BD;
  }

  .st32 {
    fill: #F6B76D;
  }

  .st33 {
    fill: #E25553;
  }

  .st34 {
    fill: #78919B;
  }

  .st35 {
    fill: #E9EDEE;
  }

  .st36 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2.7767;
    stroke-miterlimit: 10;
  }

  .st37 {
    fill: #FFFFFF;
    stroke: #1E4759;
    stroke-width: 3.2087;
    stroke-miterlimit: 10;
  }

  .st38 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 0.8759;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st39 {
    fill: #B2E2DF;
  }

  .st40 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 1.5151;
    stroke-miterlimit: 10;
  }

  .st41 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 3.0303;
    stroke-miterlimit: 10;
  }

  .st42 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2.0202;
    stroke-miterlimit: 10;
  }

  .st43 {
    fill: none;
    stroke: #1E4759;
    stroke-width: 0.9284;
    stroke-miterlimit: 10;
  }

  .st44 {
    fill: none;
    stroke: #204758;
    stroke-width: 1.1089;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st45 {
    fill: none;
    stroke: #FCE9D3;
    stroke-width: 1.1061;
    stroke-miterlimit: 10;
  }

  .st46 {
    fill: none;
    stroke: #FCE9D3;
    stroke-width: 1.2763;
    stroke-miterlimit: 10;
  }

  .st47 {
    fill: #FFFFFF;
    stroke: #1E4759;
    stroke-width: 3.0112;
    stroke-miterlimit: 10;
  }

  .st48 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 0.822;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st49 {
    font-family: 'OpenSans-SemiBold';
  }

  .st50 {
    font-size: 11.8492px;
  }

  .st51 {
    font-size: 9.4794px;
  }

  .st52 {
    font-size: 15.167px;
  }

  .st53 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 1.4219;
    stroke-miterlimit: 10;
  }

  .st54 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2.8438;
    stroke-miterlimit: 10;
  }

  .st55 {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 1.8959;
    stroke-miterlimit: 10;
  }

  .st56 {
    font-size: 7.4839px;
  }

  .st57 {
    fill: none;
    stroke: #1E4759;
    stroke-width: 0.8713;
    stroke-miterlimit: 10;
  }

  .st58 {
    fill: none;
    stroke: #204758;
    stroke-width: 1.0407;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st59 {
    fill: none;
    stroke: #FCE9D3;
    stroke-width: 1.038;
    stroke-miterlimit: 10;
  }

  .st60 {
    fill: none;
    stroke: #FCE9D3;
    stroke-width: 1.1977;
    stroke-miterlimit: 10;
  }
</style>
  <path class="st36" d="M477.42,490.76c4.63-16.24,7.56-33.31,9.12-38.58"/>
  <g>
	<g>
		<circle class="st14" cx="495.86" cy="270.64" r="33.78"/>
    <path class="st19" d="M574.65,432.19h-35.21c-0.12,0-0.21-0.09-0.21-0.19l10.57-136.83c0-0.11,0.1-0.19,0.21-0.19h13.86
			c0.12,0,0.21,0.09,0.21,0.19L574.65,432.19z"/>
    <circle class="st14" cx="556.94" cy="324.5" r="33.78"/>
    <circle class="st14" cx="517.66" cy="300.46" r="33.78"/>
    <rect x="70.33" y="394.77" class="st47" width="182.59" height="72.98"/>
    <g>
			<path class="st1" d="M177.76,397.38c0.07,0.02,0.58,0.06,0.75,0.52c0.15,0.4-0.21,0.21,0.86,2.19c0.22,0.4,0.83,0.96,0.66,2.15
				c-0.17,1.19,0.2,1.92,0.9,1.98c0.17,0.02,0.12-0.89,0.68-0.61c0.45,0.22,0.91,0.76,1.24,1.36c0.09,0.16-0.1,0.55,0.01,0.66
				c0.3,0.3,0.33,1.98,0.49,2.43c0.17,0.45,1.1,0.93,1.05,1.61c-0.06,0.68-0.05,1.41,0.31,2.04c0.22,0.37-0.13,1.11,1.59,1.57
				c0.43,0.11,0.19,0.28,0.84,0.51c0.64,0.23,2.2,0.83,2.06,1.6c-0.11,0.59,1.05,1.22,0.94,1.56c-0.11,0.34,0.41,0.72,0.57,1.38
				c0.11,0.48-0.03,1.19,0.79,0.82c0.44-0.2-0.11-0.11,0.17-0.45s0.2,0.26,0.71,0.57c0.51,0.31,0.57,0.17,0.57,0.62
				c0,0.45-0.35,0.56-0.18,1.07s-0.22,0.49,0.69,0.66c0.35,0.07,0.31,0.41,0.57,0.62c0.4,0.33,1.02,0.6,1.3,1.02
				c0.45,0.68,0.51,0.85,1.02,1.13c0.51,0.28,0.66,0.67,0.63,1.44c-0.03,0.59,0.46,1.1,0.17,1.94c-0.2,0.57,0.37,1.36,0.64,2.14
				c0.21,0.59,0.12,1.21,0.11,1.58c-0.02,0.66-0.54,1.47-0.59,1.81c-0.06,0.34,0.08,0.73-0.2,1.47c-0.28,0.73-0.2,1.84-0.54,2.8
				s-0.87,1.63-1.22,2.06c-0.54,0.68-1.41,1.53-1.58,2.09c-0.17,0.57-0.65,1.53-0.76,2.26c-0.11,0.73-0.25,1.16-0.51,1.36
				c-0.54,0.41-0.17,0.79-0.9,1.58c-0.6,0.64-0.13,1.03-0.42,1.81c-0.21,0.56,0,0.59,0.11,0.88s0.23,0.31,0.23,0.73
				s-1.58,0.71-2.37,0.88s-0.68-0.2-1.36,0.02c-0.68,0.23-0.93,1.5-1.98,1.96c-1.4,0.6-1.7,0.25-1.87-0.03
				c-0.17-0.28,0.08-0.82-0.37-0.99c-0.45-0.17-0.59-0.37-0.76-0.71s-0.92-0.14-1.17,0.08c-0.25,0.23,0.22,0.19-0.34,0.54
				c-0.55,0.34-0.44,1.06-1.22,1.24c-0.72,0.17-0.84-0.03-1.4-0.42c-0.58-0.41-1.84-0.3-1.99-0.76c-0.32-0.14-0.21,0.55-0.59,0.41
				c-0.43-0.15-0.34-0.81-0.78-0.82c-0.79-0.01-0.3,0.16-1.09-0.41c-0.37-0.27-0.3-0.55-0.51-0.79c-0.24-0.28-0.73-0.52-0.64-0.85
				c0.17-0.62,0.11-0.32,0.01-1.06c-0.1-0.83,0.33-0.78-0.52-1.5c-0.74-0.62-0.45-1.04-1.16-1.02c-0.39,0.01-0.71-0.05-0.94,0.02
				c-1.13,0.31-2.02,0-2.21-0.25c-0.42-0.56,0.51-0.59,0.85-0.31c0.26,0.22,0.66-0.11,0.76-0.2c0.31-0.28,0.88-0.08,0.9-0.21
				c0.06-0.37,0.33-0.96-0.21-1.2c-0.45-0.2-0.26-0.95-0.48-1.02c-0.12-0.04-0.67-0.05-0.61,0.58c0.06,0.59,0.29,1.07-0.1,1.24
				s-0.86,0.51-0.89,0.19c-0.02-0.25,0.02-0.39,0.15-0.7c0.16-0.4,0.21-1.95,0.66-2.07s0.65-0.16,0.54-0.54
				c-0.14-0.47,0.48-1.55,0.02-1.78c-0.45-0.23-0.49-0.59-0.54-0.17c-0.05,0.53,0.03,0.23-0.25,0.79c-0.28,0.57-0.15-0.23-0.41,0.96
				c-0.1,0.48-0.33,0.66-1.06,0.73c-0.36,0.03-0.55,0.62-0.79,0.9c-0.31,0.36-0.38,0.44-0.71,0.68c-0.26,0.19,0.05,0.74-0.42,0.79
				c-0.09,0.01-0.33-0.18-0.47-0.66c-0.14-0.51-0.06-0.67-0.28-1.03c-0.22-0.36-0.76-1.19-0.82-1.78c-0.03-0.28-0.65-0.05-0.89-0.16
				c-0.26-0.12-0.14-0.57-0.1-0.75c0.09-0.45-0.65-1.26-1.05-1.51c-0.4-0.25-0.86,0.07-1.26-0.1s-0.57-0.5-0.79-0.39
				c-0.09,0.05-0.39,0.35-0.56,0.35c-0.25-0.01-0.42-0.37-0.85-0.64c-0.73-0.45-0.41-0.58-1.14-0.58c-0.73,0,0.07,0.24-0.83,0.24
				c-0.9,0-2.76-0.04-3.26,0.18c-0.44,0.19-0.16,0.21-0.65,0.48c-0.08,0.05-0.64,0.33-1.19,0.37c-0.77,0.06-1.27,0.37-1.67,0.37
				s-1.3-0.24-1.75,0.1s-0.45,0.34-0.68,0.57c-0.09,0.09-0.36,0.02-0.76,0.14c-0.66,0.2-0.29,0.41-0.54,0.55
				c-0.13,0.07-0.97,0.11-1.3,0.37c-0.67,0.52-0.31,0.86-0.52,1.27c-0.21,0.4-1.99,0.68-2.93,0.59c-0.38-0.03-0.8-0.01-1.44-0.04
				c-0.97-0.05-1.87,0.03-1.87,0.03s-0.63-0.27-1.02,0.07c-0.26,0.22-0.39,0.26-0.56,0.51c-0.09,0.12-0.32,0.64-0.72,0.68
				c-1.19,0.11-0.65,0.4-1.27,0.73s-0.69,0.59-1.36,0.41c-0.54-0.15-1.6,0.28-2.11,0c-0.29-0.16-0.58-0.16-0.75-0.3
				c-0.13-0.1-0.08-0.33-0.54-0.45c-0.2-0.05-0.15-0.42-0.3-0.49c-0.65-0.32-1.04-0.48-1.09-0.81c-0.06-0.4-0.28-0.38,0-0.66
				c0.28-0.28,0.06,0.09,0.64-0.28c0.57-0.37,0.52-0.66,0.48-1.17c-0.05-0.6-0.21-0.71-0.04-1.22s0.68-1.26,0.23-1.65
				c-0.74-0.65-0.68-1.53-0.85-1.92c-0.17-0.4-0.04-1.78-0.31-2.35c-0.28-0.58,0.02-1.22-0.43-1.84s-0.14-0.44-0.87-0.95
				s-0.2-1.03-0.48-1.71s-0.57-1.19-0.68-1.64c-0.11-0.45-0.08-0.82,0.15-0.83c0.31-0.01,0.06,0.73,0.57,0.37
				c0.44-0.31,0.75-1.2,0.18-1.7c-0.57-0.5-0.9-1.4-1.19-1.74c-0.28-0.34,0.05-0.19-0.29-0.75c-0.34-0.57-0.04-0.88,0.36-1.34
				s0.25-3.1,0.47-3.11c0.39-0.03,0.3,0.62,0.71,0.73c0.48,0.13,0.51-1.02,1.07-1.24c0.57-0.23,0.96-1.02,1.64-1.19
				c0.68-0.17,0.97-1.06,1.48-1.06c0.51,0,0.91,0.26,1.69,0.23c0.23-0.01,0.6-0.39,0.73-0.58c0.31-0.45,1.31,0.13,1.43-0.21
				c0.11-0.34,0.09-0.63,0.43-0.69c0.34-0.06,1.14-0.06,1.93-0.23s1.31,0.06,1.65-0.39c0.17-0.22,0.91-0.64,1.12-1.37
				c0.27-0.9,1.28-1.34,1.48-1.49c0.4-0.28-0.35-0.59-0.3-0.99c0.06-0.4-0.17-0.9,0.23-1.07s0.62-0.17,0.85-0.4
				c0.23-0.23,0.23-0.59,0.57-0.45c0.17,0.07,0.11,0.73,0.17,0.9c0.06,0.17,0.11,0.84,0.56,0.67s0.6-0.16,0.88-0.73
				c0.28-0.57-0.48-0.62-0.54-0.96c-0.06-0.34,0.4-0.41,0.65-0.38s0.22,0.49,0.62,0.27c0.4-0.23,1.22-0.05,1.11-0.34
				c-0.11-0.28-0.82-0.27-0.71-0.49c0.11-0.23-0.74-0.87-0.29-0.93s-0.12-0.03,0.28,0.03c0.4,0.06-0.18-0.52,0.27-0.52
				s-0.04,0.5,0.63,0.4c0.4-0.06-0.26-0.68,0.19-0.74c0.45-0.06-0.11-0.49,0.45-0.95s0.25-0.07,0.71-0.13
				c0.45-0.06,0.73-0.96,1.13-0.79s0.4-0.4,1.07-0.45c0.68-0.06,0.95,0.38,1.17,0.59c0.16,0.15,0.44,0.41,0.52,0.79
				c0.06,0.28-0.45,0.65-0.4,0.88c0.06,0.23-0.28,0.79,0,1.02c0.28,0.23,0.23,0.1,0.79-0.3c0.57-0.4,0.4-0.72,0.45-0.89
				c0.06-0.17,0.73,0.06,0.9,0.17c0.17,0.11,0.79,0.51,1.13,0.28c0.34-0.23,0.62-0.85,0.51-1.07c-0.11-0.23-0.85-0.45-0.68-0.68
				c0.17-0.23,0.23-0.45,0.34-0.73c0.11-0.28,0.06-0.9,0.57-0.9c0.51,0,0.23-0.28,0.45-0.68c0,0-0.34-0.79,0.68-0.73
				s0.17-0.57,0.73-0.68c0.57-0.11,2.14,0.28,2.28-0.06c0.08-0.21,0.41-1.5,0.99-1.58c0.23-0.03,0.19-0.17,0.59,0.4
				c0.29,0.41,0.61,0.17,1.01,0.34s0.3,0.65,1.26,0.65s0.21-0.2,0.95-0.14s-0.17,0.34,0.51,0.34c0.68,0-0.11,0.51,0.96,0.45
				c1.07-0.06,0.4-0.68,1.13-0.73c0.73-0.06,0.96,0.34,0.57,0.68s-1.24,1.02-1.41,1.13s-0.73,1.53-0.57,1.87s-0.85,0.62-0.9,0.85
				c-0.06,0.23,0.68,1.07,0.68,1.07s0.73,0.23,0.9,0.34s0.14,1.35,0.73,1.13c0.44-0.16,1.24,0.04,1.55,0.17
				c0.22,0.09,0.31,0.27,0.31,0.27s0.88-0.09,0.76,0.25c-0.07,0.19,0.64,0.81,1.84,1.23c0.41,0.14,0.06,0.62,0.45,0.79
				c0.4,0.17,0.71,0.54,1.78,0.48c1.07-0.06,0.99-0.03,1.27-0.48c0.28-0.45,0.23-0.85,0.62-1.41c0.4-0.57,0.24-0.57,0.45-0.9
				c0.33-0.51-0.06-1.36,0.28-1.41c0.34-0.06,0.28-0.4,0.17-0.73c-0.11-0.34-0.76-2.88-0.08-3c0.4-0.07,0.14-0.51,0.08-0.79
				c-0.06-0.28,0.53-0.33,0.55-0.52c0.03-0.27-0.38-0.37-0.31-0.64c0.05-0.2,0.1-0.46,0.41-0.99
				C177.44,398.49,177.37,397.27,177.76,397.38z"/>
      <path class="st1" d="M188.38,458.36c-0.15-0.04-0.9,0.3-1.17,0.34c-0.26,0.04-0.87,0.3-0.87,0.45c0,0.15,0.3,0.72,0,0.64
				c-0.3-0.08-0.08-0.45-0.57-0.45c-0.49,0-0.83,0.23-1.02,0c-0.19-0.23-0.26-0.45-0.64-0.45s-0.23-0.38-0.72-0.38
				c-0.49,0-0.15-0.38-0.64-0.41c-0.49-0.04-1.06-0.26-0.94,0c0.11,0.26-0.14,0.75-0.01,1.02c0.13,0.26,0.54,0.26,0.47,0.45
				c-0.08,0.19-0.34,0.6,0.04,0.72c0.38,0.11-0.15,0.68,0.34,0.68s0.49,0.34,0.49,0.45s0.38,0.26,0.49,0.41c0.11,0.15-0.57,0-0.57,0
				s-0.3,0.53,0,0.79c0.3,0.26,1.17,1.06,0.98,1.24c-0.19,0.19,0.45,0.34,0.64,0.53c0.19,0.19,0.02,0.38,0.39,0.49
				c0.36,0.11,0.59,0.41,0.82,0.19c0.23-0.23-0.23-0.87,0.23-0.94c0.45-0.08,0.19-0.75,0.53-0.83s0.64,0,0.9-0.08
				c0.26-0.08,0.45-0.38,0.26-0.68s-0.15-0.34,0.11-0.57c0.26-0.23-0.38-0.68,0.11-0.83c0.49-0.15,0.45-0.6,0.57-0.9
				c0.11-0.3,0-1.02,0-1.28S188.38,458.36,188.38,458.36z"/>
		</g>
    <g>
			<rect x="369.92" y="119.62" class="st5" width="9.34" height="105.27"/>
      <g>
				<path class="st6" d="M459.35,124.42H289.83c-0.92,0-1.67-0.75-1.67-1.67V67.3c0-0.92,0.75-1.67,1.67-1.67h169.53
					c0.92,0,1.67,0.75,1.67,1.67v55.44C461.03,123.67,460.28,124.42,459.35,124.42z"/>
        <path class="st48" d="M454.37,119.62H294.81c-0.92,0-1.67-0.75-1.67-1.67V72.1c0-0.92,0.75-1.67,1.67-1.67h159.56
					c0.92,0,1.67,0.75,1.67,1.67v45.85C456.04,118.87,455.29,119.62,454.37,119.62z"/>
        <text transform="matrix(1 0 0 1 299.7646 91.3203)"><tspan x="0" y="0" class="st4 st49 st50">TRAVEL </tspan>
          <tspan x="0" y="14.22" class="st4 st49 st50">SAVED</tspan></text>
        <text transform="matrix(1 0 0 1 434.3398 98.7851)" class="st4 st49 st51">Km</text>
			</g>
      <text transform="matrix(1 0 0 1 360.2768 99.8086)" class="st4 st49 st52">{{summary?.consumers?.km | number}}</text>
		</g>
    <g>
			<path class="st15" d="M419.16,272.82c7.62,4.84,13.51-0.89,24.6-2.05c5.83-0.61,14.11-5.93,15.29-11.37
				c1.23-5.64,3.99-6.48,9.64-5.38c7.45,1.45,10.51-8.57,13.73-12.87c4.63-6.19-2.21-8.7,3.94-13.86
				c3.62-3.03-10.02-7.25-16.87-2.46c-2.58,1.81-5.2,4.88-7.69,9.76c-3.39,6.64-14.24,11.64-13.73,19.39
				c0.33,5-11.22,6.87-13.53,11.56C431.57,271.55,418.36,272.31,419.16,272.82z"/>
      <path class="st15" d="M492.94,163.85c-12.93,6.27,21.16,24.91,48.74,33.31c27.58,8.39,69.2,14.88,82.74,8.3
				c3.13-1.52,2.51-9.55,3.97-11.82c2.87-4.45,4.89-4.56-0.48-11.75c-2.78-3.72,2.64-9.44-3.06-20.42
				c-10.6-20.43-39.33-3.53-54.86-8.25c-15.41-4.69-27.37-15.47-41.09-8.61c-7.63,3.81-13.35,1.66-20.01,2.4
				C493.86,148.67,502.64,159.14,492.94,163.85z"/>
      <path class="st15" d="M458.29,240.83c3.91,5.77,12.34,8.74,23.39,9.07c3.36,0.1,6.96-0.04,10.75-0.43
				c14.7-1.49,32.26-6.58,49.61-15.06c36.54-17.85,58.3-43.91,48.59-58.22c-9.71-14.31-47.21-11.44-83.75,6.41
				c-19.05,9.3-34.08,20.84-42.73,31.78c-3.12,3.95,2.43,8.72-6.76,11.49C450.89,227.82,455.47,236.67,458.29,240.83z"/>
      <path class="st15" d="M464.01,186.53c-2.45,4.26,1.91,7.84-1.2,13.36c-7.29,12.97,26.08,27.59,56.25,34.73
				c20.38,4.83,47.08,14.58,57.37,3.06c9.72-10.89,18.18-1.54,24.44-1.8c15.35-0.66,13.92-9.39,17.89-10.48
				c13.69-3.79,0.5-21.57-1.06-26.14c-4.68-13.69-32.91-19.19-63.08-26.34c-24.14-5.72-44.02-13.6-61.68-9.08
				c-6.98,1.79-20.5,6.36-21.82,15.26C470.61,182.5,465.61,183.76,464.01,186.53z"/>
      <path class="st15" d="M526.44,205.61c16.43,8.6,44.45,0.18,62.58-18.81c8.99-9.42,40.63-27.71,22.63-37.17
				c-5.61-2.95-10.88,1.45-12.1-6.53c-1.78-11.54-27.36-15.33-33.29-8.43c-5.78,6.73-15.86-5.95-21.03,8.48
				c-2.02,5.65-11,7.59-15.69,12.5C511.4,174.64,510.01,197,526.44,205.61z"/>
      <path class="st15" d="M485.49,192.64c-12.83,9.52-8.42,29.46,9.87,44.54c5.24,4.32,15,3.42,16.96,10.28
				c1.82,6.36,9.52,2.87,15.94,3.12c7.49,0.29,18.31,1.16,19.33-4.35c1.18-6.41,8.81-3.53,10.01-6.38
				c4.39-10.44-1.42-25.28-15.78-37.12C523.55,187.63,498.32,183.12,485.49,192.64z"/>
		</g>
    <g>
			<g>
				<circle class="st35" cx="177.28" cy="94.03" r="33.78"/>
        <circle class="st35" cx="218.27" cy="85.25" r="33.78"/>
        <circle class="st35" cx="239.72" cy="111.48" r="33.78"/>
        <circle class="st35" cx="199.08" cy="127.81" r="33.78"/>
			</g>
      <circle class="st10" cx="127.76" cy="110.92" r="61.7"/>
      <g>
				<g>
					<g>
						<text transform="matrix(1 0 0 1 80.6787 113.9424)"><tspan x="0" y="0" class="st2 st49 st50">TOTAL NUMBER </tspan>
              <tspan x="7.19" y="14.22" class="st2 st49 st50">OF CONSULTS</tspan></text>
            <text transform="matrix(1 0 0 1 114.7773 148.3486)" class="st2 st49 st52">{{consults | number}}</text>
					</g>
          <path class="st12" d="M132.77,69.2c-1.06,0-1.93,0.86-1.93,1.93c0,0.07,0.01,0.14,0.02,0.21c0.11,0.96,0.91,1.72,1.91,1.72
						c0.99,0,1.8-0.75,1.91-1.72c0.01-0.07,0.02-0.14,0.02-0.21C134.69,70.07,133.83,69.2,132.77,69.2"/>
				</g>
        <g>
					<path class="st12" d="M126.6,88.83h12.33c-1.4-1.89-3.63-3.11-6.16-3.11C130.24,85.71,128,86.94,126.6,88.83"/>
          <path class="st12" d="M128.39,80.03c0,2.41,1.96,4.37,4.37,4.37c2.41,0,4.37-1.96,4.37-4.37c0-2.41-1.96-4.37-4.37-4.37
						C130.35,75.66,128.39,77.62,128.39,80.03"/>
          <path class="st12" d="M120.16,73.18h10.32c-0.45-0.5-0.73-1.14-0.78-1.84h-9.64c-0.96,0-1.74,0.78-1.74,1.74v1.45
						c0.64,0.03,1.26,0.14,1.84,0.32V73.18z"/>
          <path class="st12" d="M145.47,71.34h-9.64c-0.05,0.71-0.33,1.35-0.78,1.84h10.32v16.77h-23.98c1.18,0.42,2.31,1.04,3.34,1.85
						h3.75h8.57h8.42c0.96,0,1.74-0.78,1.74-1.74V73.08C147.21,72.12,146.43,71.34,145.47,71.34"/>
          <path class="st12" d="M123.11,91.8c-1.45-0.89-3.06-1.42-4.77-1.5c-0.15-0.01-0.29-0.02-0.44-0.02c-3.93,0-7.42,2.41-9.59,6.12
						h19.18C126.35,94.45,124.85,92.87,123.11,91.8"/>
          <path class="st12" d="M120.16,75.84c-0.58-0.21-1.2-0.33-1.84-0.37c-0.14-0.01-0.27-0.02-0.42-0.02c-3.75,0-6.8,3.04-6.8,6.8
						c0,3.76,3.05,6.8,6.8,6.8c0.14,0,0.28-0.01,0.42-0.02c0.64-0.04,1.26-0.16,1.84-0.37c2.64-0.93,4.54-3.45,4.54-6.41
						C124.7,79.29,122.81,76.77,120.16,75.84"/>
				</g>
			</g>
		</g>
    <g>
			<text transform="matrix(1 0 0 1 580.0239 209.2353)" class="st2 st49 st51">Kg CO</text>
      <text transform="matrix(0.583 0 0 0.583 607.2861 212.3916)" class="st2 st49 st51">2</text>
      <text transform="matrix(1 0 0 1 600.4409 209.2353)" class="st2 st49 st51"> </text>
      <text transform="matrix(1 0 0 1 509.2221 210.5517)" class="st2 st49 st52">{{summary?.consumers?.co2 | number:'1.0-0'}}</text>
		</g>
    <path class="st39" d="M500.82,436.37c28.43-23.34,144.2-14.45,161.94,3.63c34.29,34.94,22.38,82.32,22.38,82.32l-201.56-0.8
			C484.5,509.53,475.47,457.18,500.82,436.37z"/>
    <path class="st16" d="M225.68,217.66c8.29,3.2,29-0.28,62.52,20.55c20.28,12.61,29.78,13.81,53.38,30.67
			c16.97,12.12,96.81-0.41,114.77,18.68c26.27,27.92,13.81,80.87,15.68,110.61c6.29,99.94,37.17,124.15,37.17,124.15H266.31
			c0,0,30.57-5.54,75.27-35.89c22.7-15.41,78.56-101.24,57.5-165.4c-10.32-31.43-73.08-29.66-100.21-47.3
			c-33.4-21.71-86.67-29.55-87.04-32.29C209.13,221.02,225.68,217.66,225.68,217.66z"/>
    <path class="st53" d="M262.69,239.43c-3.2-1.35-23.38-10.29-31.77-11.88"/>
    <path class="st54" d="M431.22,441.41c2.58-13.07,3.14-24.83,3.14-39.51"/>
    <path class="st54" d="M437.45,369.99c1.03-17.91-0.24-31.04-3.24-42.2"/>
    <path class="st54" d="M424.89,302.67c-6.17-14.75-16.39-14.37-26.19-17.08"/>
    <path class="st55" d="M374.68,283.72c-5.16-2.12-29.43-4.07-36.3-5.65"/>
    <g>
			<path class="st32" d="M418.07,269.5l0.36,0c0.8,0,1.45-0.66,1.45-1.46l0-3.35c0-0.8-0.66-1.45-1.46-1.45l-0.36,0
				c-0.8,0-1.45,0.66-1.45,1.46l0,3.35C416.62,268.85,417.27,269.5,418.07,269.5z"/>
      <path class="st2" d="M403.33,240.85c-1.32-0.56-30.84-0.6-32.44,0.16c-2.33,1.11-13.77,15.63-12.82,15.76
				c1.89,0.28,53.43,0.27,54.93-0.13C414.5,256.24,404.75,241.46,403.33,240.85z"/>
      <path class="st33" d="M337.28,273.85l76.95-0.08c4.85-0.01,3.97-3.97,3.96-8.82v0c-0.01-4.85-2.23-8.82-8.83-8.81
				c-6.6,0.01-50.34,0.05-50.34,0.05c0.67-1.65,1.4-3.76-0.06-3.18c-1.48,0.58-7.75,0.38-16.53,2.78c-5.76,1.57-6.13,2.61-9.18,8.67
				l0,1.06C333.26,270.36,332.43,273.85,337.28,273.85z"/>
      <path class="st31" d="M332.52,275.65l86.08-0.09c0.69,0,1.25-0.57,1.25-1.26l0,0c0-0.69-0.31-2.88-1.92-2.86
				c-2.7,0.02-3.8,1.61-3.8,1.61l-75,0.08c0,0-0.64-2.19-6.6-2.18c-1.25,0-1.26,2.76-1.26,3.45l0,0
				C331.26,275.09,331.83,275.65,332.52,275.65z"/>
      <circle class="st2" cx="401.09" cy="275.58" r="9.19"/>
      <circle class="st31" cx="401.09" cy="275.58" r="5.71"/>
      <circle class="st2" cx="349.85" cy="275.42" r="9.19"/>
      <circle class="st31" cx="349.85" cy="275.42" r="5.71"/>
      <path class="st34" d="M401.05,244.42c-0.85,0-13.42-0.51-13.2,0.01s0.32,9.82,0.01,10.14c-0.31,0.32,17.96,0.17,18.91,0.17
				C407.72,254.74,402.21,244.41,401.05,244.42z"/>
      <path class="st34" d="M371.9,244.18c0.85,0,11.82-0.32,11.83,0.25c0,1.16,0.02,8.49,0.01,10.14c0,0.45-18.48,0.16-19.43,0.16
				C363.35,254.74,370.74,244.18,371.9,244.18z"/>
      <path class="st34" d="M368.39,243.26c-0.84,0.32-9.51,9.8-9.14,10.67c0.37,0.86-1.17,2.85,0.57,2.04
				c1.36-0.63,9.97-11.21,9.97-11.87C369.79,243.45,368.39,243.26,368.39,243.26z"/>
      <path class="st20" d="M417.37,269.5l0.19,0c0.41,0,0.75-0.34,0.75-0.75l-0.01-4.76c0-0.41-0.34-0.75-0.75-0.75l-0.19,0
				c-0.41,0-0.75,0.34-0.75,0.75l0,4.76C416.62,269.16,416.96,269.5,417.37,269.5z"/>
      <path class="st32" d="M332.86,269.01l-0.47,0c-0.63,0-1.14-0.51-1.14-1.14l0-2.28c0-0.63,0.51-1.14,1.14-1.14l0.47,0
				c0.63,0,1.14,0.51,1.14,1.14l0,2.28C334,268.5,333.49,269.01,332.86,269.01z"/>
      <path class="st20" d="M333.41,269.01l-0.24,0c-0.32,0-0.59-0.26-0.59-0.59l0-3.39c0-0.32,0.26-0.59,0.59-0.59l0.24,0
				c0.32,0,0.59,0.26,0.59,0.59l0,3.39C334,268.75,333.74,269.01,333.41,269.01z"/>
		</g>
    <path class="st55" d="M313.82,265.83c-2.94-1.86-21.35-14.03-29.36-16.99"/>
    <rect x="64.58" y="229.57" class="st18" width="184.04" height="97.6"/>
    <rect x="60.28" y="216.16" class="st21" width="192.65" height="13.61"/>
    <g>
			<rect x="140.77" y="303.89" class="st13" width="15.4" height="23.27"/>
      <rect x="157.7" y="303.89" class="st13" width="15.4" height="23.27"/>
		</g>
    <polygon class="st22" points="156.6,180.25 60.28,210.24 252.93,210.24 		"/>
    <rect x="70.27" y="210.44" class="st22" width="172.67" height="5.52"/>
    <text transform="matrix(1 0 0 1 146.3349 226.0185)" class="st2 st49 st56">BANK</text>
    <polygon class="st23" points="156.6,187.04 84.36,209.52 228.84,209.52 		"/>
    <text transform="matrix(1 0 0 1 113.5927 294.1269)" class="st2 st49 st52">{{summary?.consumers?.costs | currency:'AUD':'symbol-narrow':'4.0-2'}}</text>
    <rect x="155.25" y="467.75" class="st2" width="12.76" height="46.61"/>
    <path class="st2" d="M117.64,388.23h-12.43c0-3.43,2.78-6.21,6.21-6.21C114.85,382.01,117.64,384.8,117.64,388.23z"/>
    <path class="st2" d="M167.85,388.23h-12.43c0-3.43,2.78-6.21,6.21-6.21S167.85,384.8,167.85,388.23z"/>
    <path class="st2" d="M218.05,388.23h-12.43c0-3.43,2.78-6.21,6.21-6.21S218.05,384.8,218.05,388.23z"/>
    <line class="st57" x1="211.84" y1="388.23" x2="211.84" y2="394.77"/>
    <line class="st57" x1="161.63" y1="388.23" x2="161.63" y2="394.77"/>
    <line class="st57" x1="111.42" y1="388.23" x2="111.42" y2="394.77"/>
    <text transform="matrix(1 0 0 1 217.3735 435.6211)" class="st2 st49 st52">{{trips(summary?.consumers?.km) | number}}</text>
    <g>
			<path class="st29"
            d="M371.53,406.42h-92.7v-78.09c0-8.07,6.54-14.6,14.6-14.6h63.49c8.07,0,14.6,6.54,14.6,14.6V406.42z"/>
      <g>
				<line class="st58" x1="333.65" y1="323.4" x2="333.65" y2="330.79"/>
        <line class="st58" x1="338.15" y1="330.79" x2="333.65" y2="330.79"/>
        <path class="st58" d="M338.15,327.76v14.03c0,1.43-1.76,3.58-4.1,3.58h-5.32"/>
        <line class="st58" x1="332.55" y1="322.32" x2="338.15" y2="327.76"/>
        <path class="st31"
              d="M332.19,352.14h-19.97v-30.98c0-1,0.81-1.82,1.82-1.82h16.33c1,0,1.82,0.81,1.82,1.82V352.14z"/>
        <path class="st12" d="M326.76,332.35h-8.95c-0.6,0-1.09-0.49-1.09-1.09v-7c0-0.6,0.49-1.09,1.09-1.09h8.95
					c0.6,0,1.09,0.49,1.09,1.09v7C327.86,331.86,327.37,332.35,326.76,332.35z"/>
			</g>
      <text transform="matrix(1 0 0 1 284.7094 372.6963)" class="st2 st49 st50">FUEL SAVINGS</text>
      <text transform="matrix(1 0 0 1 303.6752 393.9853)" class="st2 st49 st52">{{fuel(summary?.consumers?.km) | number}}L</text>
      <text transform="matrix(1 0 0 1 360.1513 393.5693)" class="st2 st49 st51"></text>
		</g>
    <g>
			<text transform="matrix(1 0 0 1 99.3642 247.8096)"><tspan x="0" y="0" class="st2 st49 st50">SAVINGS IN TRAVEL, </tspan>
        <tspan x="6.75" y="12.8" class="st2 st49 st50">ACCOMODATION  </tspan>
        <tspan x="-6.82" y="25.59" class="st2 st49 st50">&amp; LOST PRODUCTIVITY</tspan></text>
		</g>
    <g>
			<g>
				<rect x="69.11" y="296.03" class="st4" width="15.4" height="25.17"/>
        <rect x="88.42" y="296.03" class="st4" width="15.4" height="25.17"/>
			</g>
      <line class="st59" x1="96.13" y1="296.03" x2="96.13" y2="321.21"/>
      <line class="st59" x1="76.81" y1="296.03" x2="76.81" y2="321.21"/>
      <line class="st60" x1="103.83" y1="304.45" x2="69.11" y2="304.45"/>
		</g>
    <g>
			<g>
				<rect x="210.5" y="296.03" class="st4" width="15.4" height="25.17"/>
        <rect x="229.82" y="296.03" class="st4" width="15.4" height="25.17"/>
			</g>
      <line class="st59" x1="237.52" y1="296.03" x2="237.52" y2="321.21"/>
      <line class="st59" x1="218.2" y1="296.03" x2="218.2" y2="321.21"/>
      <line class="st60" x1="245.22" y1="304.45" x2="210.5" y2="304.45"/>
		</g>
    <g>
			<rect x="67.3" y="235.4" class="st26" width="14.76" height="88.84"/>
      <rect x="62.77" y="229.57" class="st26" width="23.83" height="4.05"/>
      <rect x="65.34" y="233.62" class="st26" width="18.68" height="3.56"/>

      <rect x="62.77" y="327.79" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 149.3577 659.6371)" class="st26"
            width="23.83" height="4.05"/>

      <rect x="65.34" y="324.23" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 149.3577 652.0231)" class="st26"
            width="18.68" height="3.56"/>
		</g>
    <g>
			<rect x="232.04" y="235.4" class="st26" width="14.76" height="88.84"/>
      <rect x="227.51" y="229.57" class="st26" width="23.83" height="4.05"/>
      <rect x="230.09" y="233.62" class="st26" width="18.68" height="3.56"/>

      <rect x="227.51" y="327.79" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 478.8489 659.6371)" class="st26"
            width="23.83" height="4.05"/>

      <rect x="230.09" y="324.23" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 478.8489 652.0231)" class="st26"
            width="18.68" height="3.56"/>
		</g>
    <text transform="matrix(1 0 0 1 79.8911 414.2871)"><tspan x="0" y="0" class="st2 st49 st50">NO. OF TRIPS  </tspan>
      <tspan x="0" y="14.22" class="st2 st49 st50">SAVED TRAVERSING </tspan>
      <tspan x="0" y="28.44" class="st2 st49 st50">AUSTRALIA'S </tspan>
      <tspan x="0" y="42.66" class="st2 st49 st50">COASTLINE</tspan></text>
    <g>
			<circle class="st14" cx="554.14" cy="270.72" r="33.78"/>
      <circle class="st14" cx="595.12" cy="261.94" r="33.78"/>
      <circle class="st14" cx="616.58" cy="288.17" r="33.78"/>
      <circle class="st14" cx="575.93" cy="304.5" r="33.78"/>
		</g>
    <circle class="st14" cx="524.99" cy="263.38" r="33.78"/>
    <text transform="matrix(1 0 0 1 487.9453 268.3916)" class="st4 st49 st50">THAT’S EQUIVALENT TO</text>
    <g>
			<path class="st20" d="M584.21,449.83h-17.76c-0.06,0-0.11-0.07-0.11-0.17l5.33-117.68c0-0.09,0.05-0.17,0.11-0.17h6.99
				c0.06,0,0.11,0.07,0.11,0.17L584.21,449.83z"/>
      <path class="st6" d="M630.43,315.13c-0.02-0.02-0.05-0.03-0.07-0.04c1.39-2.95,1.96-6.11,1.55-9.39
				c-1.91-15.43-24.54-27.65-50.53-27.28c-14.69,0.21-27.32,4.39-35.17,10.77c-13.73-2.77-26.58-0.73-33.72,6.47
				c-6.91,6.95-6.91,17.14-1.29,27.15c-4.7,1.23-8.76,3.34-11.78,6.39c-11.52,11.6-3.86,32.17,17.11,45.96
				c18.69,12.29,41.63,15.02,54.56,7.26c17.97,7.32,36.89,6.67,46.18-2.68c3.11-3.13,4.81-6.92,5.24-11.05
				c10.31,0.54,19.44-1.93,25.05-7.58C659.06,349.49,651.4,328.91,630.43,315.13z"/>
		</g>
    <text transform="matrix(1 0 0 1 550.038 342.1074)" class="st4 st49 st52">{{tree(summary?.consumers?.co2) | number}}</text>
    <text transform="matrix(1 0 0 1 573.7075 305.5537)"><tspan x="0" y="0" class="st4 st49 st50"> </tspan>
      <tspan x="-29.52" y="14.22" class="st4 st49 st50">PLANTING</tspan></text>
    <text transform="matrix(1 0 0 1 556.6977 361.5195)" class="st4 st49 st50">TREES</text>
    <text transform="matrix(1 0 0 1 517.9199 175.4863)" class="st2 st49 st50">NET</text>
    <text transform="matrix(1 0 0 1 485.9199 188.4863)" class="st2 st49 st50">CO</text>
    <text transform="matrix(0.583 0 0 0.583 502.7563 192.4316)" class="st2 st49 st50">2 </text>
    <text transform="matrix(1 0 0 1 508.4941 188.4863)" class="st2 st49 st50">EMISSIONS SAVED</text>
    <g>
			<g>
				<g>
					<path class="st20" d="M620.41,457.48h-5.26c-0.02,0-0.03-0.02-0.03-0.05l1.58-34.87c0-0.03,0.01-0.05,0.03-0.05h2.07
						c0.02,0,0.03,0.02,0.03,0.05L620.41,457.48z"/>
          <path class="st6" d="M629.78,410.01c-0.01,0-0.01-0.01-0.02-0.01c0.2-0.94,0.21-1.91-0.02-2.85
						c-1.07-4.46-6.83-6.89-12.86-5.44c-3.41,0.82-6.17,2.71-7.74,5c-3.3-0.11-6.21,1.16-7.58,3.65c-1.32,2.41-0.91,5.42,0.81,8.08
						c-1.04,0.61-1.9,1.44-2.48,2.49c-2.21,4.02,0.41,9.7,5.85,12.68c4.85,2.66,10.29,2.28,12.98-0.68
						c4.48,1.23,8.85,0.06,10.63-3.18c0.6-1.09,0.84-2.29,0.77-3.53c2.42-0.37,4.44-1.57,5.51-3.53
						C637.84,418.68,635.22,413,629.78,410.01z"/>
				</g>
        <g>
					<path class="st20" d="M530.86,457.48h-5.26c-0.02,0-0.03-0.02-0.03-0.05l1.58-34.87c0-0.03,0.01-0.05,0.03-0.05h2.07
						c0.02,0,0.03,0.02,0.03,0.05L530.86,457.48z"/>
          <path class="st6" d="M541.32,403.76c-0.01,0-0.01-0.01-0.02-0.01c0.22-1.02,0.22-2.05-0.02-3.08
						c-1.16-4.81-7.37-7.43-13.87-5.87c-3.67,0.89-6.65,2.92-8.34,5.39c-3.56-0.12-6.69,1.25-8.17,3.94
						c-1.43,2.6-0.98,5.84,0.87,8.71c-1.13,0.65-2.05,1.55-2.67,2.69c-2.38,4.33,0.45,10.45,6.31,13.67c5.22,2.87,11.1,2.46,14-0.73
						c4.83,1.33,9.54,0.06,11.46-3.43c0.64-1.17,0.9-2.47,0.83-3.81c2.61-0.4,4.79-1.7,5.95-3.81
						C550,413.1,547.18,406.97,541.32,403.76z"/>
				</g>
        <g>
					<path class="st20" d="M575.28,522.26h-5.26c-0.02,0-0.03-0.02-0.03-0.05l1.58-34.87c0-0.03,0.01-0.05,0.03-0.05h2.07
						c0.02,0,0.03,0.02,0.03,0.05L575.28,522.26z"/>
          <path class="st6" d="M583.45,477.33c-0.01,0-0.01-0.01-0.02-0.01c0.18-0.84,0.18-1.69-0.02-2.53
						c-0.95-3.96-6.06-6.12-11.42-4.83c-3.02,0.73-5.48,2.4-6.87,4.44c-2.93-0.1-5.51,1.03-6.73,3.24
						c-1.17,2.14-0.81,4.81,0.71,7.17c-0.93,0.54-1.69,1.28-2.2,2.21c-1.96,3.57,0.37,8.61,5.19,11.26c4.3,2.36,9.14,2.02,11.52-0.6
						c3.97,1.09,7.85,0.05,9.43-2.82c0.53-0.96,0.74-2.03,0.68-3.14c2.15-0.33,3.94-1.4,4.9-3.13
						C590.6,485.02,588.27,479.98,583.45,477.33z"/>
				</g>
        <g>
					<path class="st20" d="M600.52,501.29h-5.26c-0.02,0-0.03-0.02-0.03-0.05l1.58-34.87c0-0.03,0.01-0.05,0.03-0.05h2.07
						c0.02,0,0.03,0.02,0.03,0.05L600.52,501.29z"/>
          <path class="st6" d="M610.34,448.93c-0.01,0-0.01-0.01-0.02-0.01c0.21-0.97,0.21-1.95-0.02-2.92c-1.1-4.57-7-7.06-13.18-5.57
						c-3.49,0.84-6.32,2.77-7.93,5.12c-3.39-0.11-6.36,1.19-7.76,3.74c-1.36,2.47-0.93,5.55,0.83,8.28
						c-1.07,0.62-1.95,1.47-2.54,2.56c-2.26,4.12,0.42,9.93,5.99,12.99c4.96,2.73,10.54,2.34,13.3-0.69
						c4.59,1.26,9.07,0.06,10.89-3.26c0.61-1.11,0.86-2.35,0.79-3.62c2.48-0.38,4.55-1.61,5.65-3.62
						C618.59,457.81,615.91,451.99,610.34,448.93z"/>
				</g>
        <g>
					<path class="st20" d="M550.03,501.28h-5.26c-0.02,0-0.03-0.02-0.03-0.05l1.58-34.87c0-0.03,0.01-0.05,0.03-0.05h2.07
						c0.02,0,0.03,0.02,0.03,0.05L550.03,501.28z"/>
          <path class="st6" d="M558.9,448.94c-0.01,0-0.01-0.01-0.02-0.01c0.21-0.97,0.21-1.95-0.02-2.92c-1.1-4.57-7-7.06-13.17-5.57
						c-3.49,0.84-6.32,2.77-7.93,5.12c-3.39-0.11-6.36,1.19-7.76,3.74c-1.36,2.47-0.93,5.55,0.82,8.28
						c-1.07,0.62-1.95,1.47-2.54,2.55c-2.26,4.12,0.42,9.93,5.99,12.99c4.96,2.72,10.54,2.34,13.3-0.69
						c4.59,1.26,9.06,0.06,10.88-3.26c0.61-1.11,0.86-2.35,0.79-3.62c2.48-0.38,4.55-1.61,5.65-3.62
						C567.15,457.81,564.46,452,558.9,448.94z"/>
				</g>
			</g>
      <g>
				<path class="st20" d="M633.38,513.63h-5.26c-0.02,0-0.03-0.02-0.03-0.05l1.58-34.87c0-0.03,0.01-0.05,0.03-0.05h2.07
					c0.02,0,0.03,0.02,0.03,0.05L633.38,513.63z"/>
        <path class="st6" d="M640.16,467.69c0,0-0.01,0-0.01-0.01c0.16-0.73,0.16-1.47-0.02-2.2c-0.83-3.44-5.27-5.32-9.93-4.2
					c-2.63,0.63-4.76,2.09-5.97,3.86c-2.55-0.08-4.79,0.89-5.85,2.82c-1.02,1.86-0.7,4.18,0.62,6.24c-0.81,0.47-1.47,1.11-1.91,1.92
					c-1.7,3.1,0.32,7.48,4.51,9.79c3.74,2.05,7.94,1.76,10.02-0.52c3.45,0.95,6.83,0.05,8.2-2.45c0.46-0.84,0.65-1.77,0.59-2.73
					c1.87-0.29,3.43-1.22,4.26-2.73C646.38,474.37,644.36,469.99,640.16,467.69z"/>
			</g>
      <g>
				<path class="st20" d="M517.71,513.63h-5.26c-0.02,0-0.03-0.02-0.03-0.05l1.58-34.87c0-0.03,0.01-0.05,0.03-0.05h2.07
					c0.02,0,0.03,0.02,0.03,0.05L517.71,513.63z"/>
        <path class="st6" d="M524.49,467.69c0,0-0.01,0-0.01-0.01c0.16-0.73,0.16-1.47-0.02-2.2c-0.83-3.44-5.27-5.32-9.93-4.2
					c-2.63,0.63-4.76,2.09-5.97,3.86c-2.55-0.08-4.79,0.89-5.85,2.82c-1.02,1.86-0.7,4.18,0.62,6.24c-0.81,0.47-1.47,1.11-1.91,1.92
					c-1.7,3.1,0.32,7.48,4.51,9.79c3.74,2.05,7.94,1.76,10.02-0.52c3.45,0.95,6.83,0.05,8.2-2.45c0.46-0.84,0.65-1.77,0.59-2.73
					c1.87-0.29,3.43-1.22,4.26-2.73C530.71,474.37,528.69,469.99,524.49,467.69z"/>
			</g>
		</g>
	</g>
    <g>
		<rect x="474.89" y="76.85" class="st8" width="306.72" height="18.3"/>
      <rect x="474.85" y="14.79" class="st9" width="306.72" height="62.06"/>
      <g>
			<rect x="699.17" y="45.87" class="st10" width="5.49" height="50.04"/>
        <rect x="776.08" y="45.87" class="st10" width="5.49" height="50.04"/>
        <g>
				<path class="st10" d="M739.51,6.67l-39.89,39.33c-0.29,0.38-0.45,0.94-0.45,1.53v19.25c0,1.12,0.59,2.02,1.31,2.02h79.79
					c0.72,0,1.31-0.9,1.31-2.02V47.52c0-0.59-0.16-1.14-0.45-1.53L741.23,6.67C740.73,6.01,740,6.01,739.51,6.67z"/>
          <g>
					<g>
						<rect x="704.98" y="69.25" class="st4" width="17.65" height="26.2"/>
            <path class="st10"
                  d="M722.17,69.71v25.28h-16.73V69.71H722.17 M723.09,68.79h-18.58v27.12h18.58V68.79L723.09,68.79z"/>
					</g>
            <g>
						<rect x="722.29" y="69.25" class="st4" width="17.65" height="26.2"/>
              <path class="st10"
                    d="M739.48,69.71v25.28h-16.73V69.71H739.48 M740.4,68.79h-18.58v27.12h18.58V68.79L740.4,68.79z"/>
					</g>
				</g>
          <g>
					<g>
						<rect x="740.87" y="69.25" class="st4" width="17.65" height="26.2"/>
            <path class="st10"
                  d="M758.06,69.71v25.28h-16.73V69.71H758.06 M758.98,68.79H740.4v27.12h18.58V68.79L758.98,68.79z"/>
					</g>
            <g>
						<rect x="758.18" y="69.25" class="st4" width="17.65" height="26.2"/>
              <path class="st10"
                    d="M775.37,69.71v25.28h-16.73V69.71H775.37 M776.29,68.79h-18.58v27.12h18.58V68.79L776.29,68.79z"/>
					</g>
				</g>
			</g>
		</g>
      <g>
			<text transform="matrix(1 0 0 1 488.4438 36.2835)"><tspan x="0" y="0" class="st4 st49 st50">ANNUAL HARDWARE </tspan>
        <tspan x="0" y="14.22" class="st4 st49 st50">EQUIPMENT AND </tspan>
        <tspan x="0" y="28.44" class="st4 st49 st50">SERVICE COST SAVINGS </tspan></text>
		</g>
      <g>
			<text transform="matrix(1 0 0 1 628.022 47.639)" class="st4 st49 st52">{{summary?.equipmentOrService?.costs | currency:'AUD':'symbol-narrow':'4.0-2'}}</text>
		</g>
      <g>
			<polygon class="st11" points="744,40.7 746.18,43.34 753.1,36.84 757.76,34.1 760.17,29.65 757.76,27.22 753.07,29.99
				750.88,34.1 			"/>
        <path class="st12" d="M740.7,51.68l-5.11-4.89c0,0-10.36,10.04-11.78,11.82s-1,3.67,0.3,5.1c1.3,1.43,3.61,0.76,5.25-0.51
				C730,62.7,740.7,51.68,740.7,51.68z"/>
        <polygon class="st13" points="736.92,36.84 750.74,50.92 746.74,55.35 732.76,40.88 			"/>
        <path class="st13" d="M727.05,27.79l4.05,4.05c0.8,0.8,0.8,2.12,0,2.93c-0.8,0.8-2.12,0.8-2.93,0l-4.05-4.05
				c-0.94,2.37-0.23,5.46,2,7.69c2.85,2.85,7.08,3.22,9.46,0.85c2.38-2.38,2-6.61-0.85-9.46C732.5,27.56,729.42,26.85,727.05,27.79z
				"/>
        <path class="st13" d="M756.84,63.64l-4.05-4.05c-0.8-0.8-0.8-2.12,0-2.93c0.8-0.8,2.12-0.8,2.93,0l4.05,4.05
				c0.94-2.37,0.23-5.46-2-7.69c-2.85-2.85-7.08-3.22-9.46-0.85c-2.38,2.38-2,6.61,0.85,9.46
				C751.38,63.86,754.47,64.58,756.84,63.64z"/>
		</g>
	</g>
</g>
</svg>
