<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 841.9 536.2" style="enable-background:new 0 0 841.9 536.2;" xml:space="preserve">
<style type="text/css">
	.st0{fill:none;stroke:#FFFFFF;stroke-width:2.7767;stroke-miterlimit:10;}
	.st1{fill:#66C6C0;}
	.st2{fill:#F6A28C;}
	.st3{fill:#FFFFFF;stroke:#1E4759;stroke-width:3.2087;stroke-miterlimit:10;}
	.st4{fill:#CCECEA;}
	.st5{fill:#8EA3AC;}
	.st6{fill:#009F96;}
	.st7{fill:none;stroke:#FFFFFF;stroke-width:0.8759;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st8{fill:#FFFFFF;}
	.st9{font-family:'OpenSans';}
	.st10{font-size:12.6261px;}
	.st11{font-size:10.1009px;}
	.st12{font-size:16.1614px;}
	.st13{fill:#CDD6DA;}
	.st14{fill:#E9EDEE;}
	.st15{fill:#F4B672;}
	.st16{fill:#1E4759;}
	.st17{fill:#204758;}
	.st18{font-size:5.8888px;}
	.st19{fill:#B2E2DF;}
	.st20{fill:#4B6C7A;}
	.st21{fill:none;stroke:#FFFFFF;stroke-width:1.5151;stroke-miterlimit:10;}
	.st22{fill:none;stroke:#FFFFFF;stroke-width:3.0303;stroke-miterlimit:10;}
	.st23{fill:none;stroke:#FFFFFF;stroke-width:2.0202;stroke-miterlimit:10;}
	.st24{fill:#F6B76D;}
	.st25{fill:#E25553;}
	.st26{fill:#A5B5BD;}
	.st27{fill:#78919B;}
	.st28{fill:#F5896B;}
	.st29{fill:#FCE9D3;}
	.st30{fill:#FADBB6;}
	.st31{fill:#D2DADE;}
	.st32{fill:#FDF1E2;}
	.st33{font-size:7.9746px;}
	.st34{fill:#FEF8F0;}
	.st35{fill:none;stroke:#1E4759;stroke-width:0.9284;stroke-miterlimit:10;}
	.st36{fill:#FDE8E2;}
	.st37{fill:none;stroke:#204758;stroke-width:1.1089;stroke-linecap:round;stroke-miterlimit:10;}
	.st38{fill:none;stroke:#FCE9D3;stroke-width:1.1061;stroke-miterlimit:10;}
	.st39{fill:none;stroke:#FCE9D3;stroke-width:1.2763;stroke-miterlimit:10;}
	.st40{fill:#F9D4A7;}
	.st41{font-size:7.361px;}
	.st42{font-size:11px;}
</style>
<path class="st0" d="M477.4,490.8c4.6-16.2,7.6-33.3,9.1-38.6"/>
<circle class="st1" cx="567.1" cy="254.1" r="36"/>
<path class="st2" d="M651,426.3h-37.5c-0.1,0-0.2-0.1-0.2-0.2l11.3-145.8c0-0.1,0.1-0.2,0.2-0.2h14.8c0.1,0,0.2,0.1,0.2,0.2
	L651,426.3z"/>
<circle class="st1" cx="632.2" cy="311.5" r="36"/>
<circle class="st1" cx="590.3" cy="285.9" r="36"/>
<rect x="113.7" y="386.4" class="st3" width="194.6" height="77.8"/>
<g>
	<path class="st4" d="M246.8,389.2c0.1,0,0.6,0.1,0.8,0.6c0.2,0.4-0.2,0.2,0.9,2.3c0.2,0.4,0.9,1,0.7,2.3s0.2,2,1,2.1
		c0.2,0,0.1-1,0.7-0.6c0.5,0.2,1,0.8,1.3,1.5c0.1,0.2-0.1,0.6,0,0.7c0.3,0.3,0.4,2.1,0.5,2.6s1.2,1,1.1,1.7s-0.1,1.5,0.3,2.2
		c0.2,0.4-0.1,1.2,1.7,1.7c0.4,0.1,0.2,0.3,0.9,0.5c0.7,0.2,2.4,0.9,2.2,1.7c-0.1,0.6,1.1,1.3,1,1.7c-0.1,0.4,0.4,0.8,0.6,1.5
		c0.1,0.5,0,1.3,0.8,0.9c0.5-0.2-0.1-0.1,0.2-0.5s0.2,0.3,0.8,0.6c0.5,0.3,0.6,0.2,0.6,0.7s-0.4,0.6-0.2,1.1s-0.2,0.5,0.7,0.7
		c0.4,0.1,0.3,0.4,0.6,0.7c0.4,0.4,1.1,0.6,1.4,1.1c0.5,0.7,0.5,0.9,1.1,1.2c0.5,0.3,0.7,0.7,0.7,1.5c0,0.6,0.5,1.2,0.2,2.1
		c-0.2,0.6,0.4,1.4,0.7,2.3c0.2,0.6,0.1,1.3,0.1,1.7c0,0.7-0.6,1.6-0.6,1.9s0.1,0.8-0.2,1.6c-0.3,0.8-0.2,2-0.6,3
		c-0.4,1-0.9,1.7-1.3,2.2c-0.6,0.7-1.5,1.6-1.7,2.2s-0.7,1.6-0.8,2.4s-0.3,1.2-0.5,1.5c-0.6,0.4-0.2,0.8-1,1.7
		c-0.6,0.7-0.1,1.1-0.5,1.9c-0.2,0.6,0,0.6,0.1,0.9s0.2,0.3,0.2,0.8s-1.7,0.8-2.5,0.9s-0.7-0.2-1.5,0c-0.7,0.2-1,1.6-2.1,2.1
		c-1.5,0.6-1.8,0.3-2,0s0.1-0.9-0.4-1s-0.6-0.4-0.8-0.8s-1-0.1-1.2,0.1s0.2,0.2-0.4,0.6c-0.6,0.4-0.5,1.1-1.3,1.3
		c-0.8,0.2-0.9,0-1.5-0.5c-0.6-0.4-2-0.3-2.1-0.8c-0.3-0.1-0.2,0.6-0.6,0.4c-0.5-0.2-0.4-0.9-0.8-0.9c-0.8,0-0.3,0.2-1.2-0.4
		c-0.4-0.3-0.3-0.6-0.5-0.8c-0.2-0.3-0.8-0.5-0.7-0.9c0.2-0.7,0.1-0.3,0-1.1c-0.1-0.9,0.4-0.8-0.6-1.6c-0.8-0.7-0.5-1.1-1.2-1.1
		c-0.4,0-0.8,0-1,0c-1.2,0.3-2.1,0-2.4-0.3c-0.4-0.6,0.5-0.6,0.9-0.3c0.3,0.2,0.7-0.1,0.8-0.2c0.3-0.3,0.9-0.1,1-0.2
		c0.1-0.4,0.4-1-0.2-1.3c-0.5-0.2-0.3-1-0.5-1.1c-0.1,0-0.7-0.1-0.6,0.6c0.1,0.6,0.3,1.1-0.1,1.3s-0.9,0.5-0.9,0.2
		c0-0.3,0-0.4,0.2-0.7c0.2-0.4,0.2-2.1,0.7-2.2c0.5-0.1,0.7-0.2,0.6-0.6c-0.1-0.5,0.5-1.7,0-1.9s-0.5-0.6-0.6-0.2
		c-0.1,0.6,0,0.2-0.3,0.8s-0.2-0.2-0.4,1c-0.1,0.5-0.4,0.7-1.1,0.8c-0.4,0-0.6,0.7-0.8,1c-0.3,0.4-0.4,0.5-0.8,0.7
		c-0.3,0.2,0.1,0.8-0.4,0.8c-0.1,0-0.4-0.2-0.5-0.7c-0.1-0.5-0.1-0.7-0.3-1.1s-0.8-1.3-0.9-1.9c0-0.3-0.7,0-0.9-0.2
		c-0.3-0.1-0.1-0.6-0.1-0.8c0.1-0.5-0.7-1.3-1.1-1.6s-0.9,0.1-1.3-0.1c-0.4-0.2-0.6-0.5-0.8-0.4c-0.1,0-0.4,0.4-0.6,0.4
		c-0.3,0-0.4-0.4-0.9-0.7c-0.8-0.5-0.4-0.6-1.2-0.6s0.1,0.3-0.9,0.3c-1,0-2.9,0-3.5,0.2c-0.5,0.2-0.2,0.2-0.7,0.5
		c-0.1,0-0.7,0.4-1.3,0.4c-0.8,0.1-1.4,0.4-1.8,0.4s-1.4-0.3-1.9,0.1c-0.5,0.4-0.5,0.4-0.7,0.6c-0.1,0.1-0.4,0-0.8,0.1
		c-0.7,0.2-0.3,0.4-0.6,0.6c-0.1,0.1-1,0.1-1.4,0.4c-0.7,0.6-0.3,0.9-0.6,1.4c-0.2,0.4-2.1,0.7-3.1,0.6c-0.4,0-0.9,0-1.5,0
		c-1,0-2,0-2,0s-0.7-0.3-1.1,0.1c-0.3,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.3,0.7-0.8,0.7c-1.3,0.1-0.7,0.4-1.4,0.8s-0.7,0.6-1.4,0.4
		c-0.6-0.2-1.7,0.3-2.2,0c-0.3-0.2-0.6-0.2-0.8-0.3c-0.1-0.1-0.1-0.4-0.6-0.5c-0.2,0-0.2-0.5-0.3-0.5c-0.7-0.3-1.1-0.5-1.2-0.9
		c-0.1-0.4-0.3-0.4,0-0.7s0.1,0.1,0.7-0.3c0.6-0.4,0.6-0.7,0.5-1.2c-0.1-0.6-0.2-0.8-0.1-1.3s0.7-1.3,0.2-1.8
		c-0.8-0.7-0.7-1.6-0.9-2c-0.2-0.4-0.1-1.9-0.3-2.5c-0.3-0.6,0-1.3-0.5-2c-0.5-0.7-0.1-0.5-0.9-1s-0.2-1.1-0.5-1.8
		c-0.3-0.7-0.6-1.3-0.7-1.8c-0.1-0.5-0.1-0.9,0.2-0.9c0.3,0,0.1,0.8,0.6,0.4c0.5-0.3,0.8-1.3,0.2-1.8s-1-1.5-1.3-1.9
		s0.1-0.2-0.3-0.8c-0.4-0.6,0-0.9,0.4-1.4s0.3-3.3,0.5-3.3c0.4,0,0.3,0.7,0.8,0.8c0.5,0.1,0.5-1.1,1.1-1.3s1-1.1,1.8-1.3
		c0.7-0.2,1-1.1,1.6-1.1c0.5,0,1,0.3,1.8,0.2c0.2,0,0.6-0.4,0.8-0.6c0.3-0.5,1.4,0.1,1.5-0.2s0.1-0.7,0.4-0.7s1.2-0.1,2.1-0.2
		s1.4,0.1,1.8-0.4c0.2-0.2,1-0.7,1.2-1.5c0.3-1,1.4-1.4,1.6-1.6c0.4-0.3-0.4-0.6-0.3-1c0.1-0.4-0.2-1,0.2-1.1
		c0.4-0.2,0.7-0.2,0.9-0.4c0.2-0.2,0.2-0.6,0.6-0.5c0.2,0.1,0.1,0.8,0.2,1s0.1,0.9,0.6,0.7s0.6-0.2,0.9-0.8s-0.5-0.7-0.6-1
		c-0.1-0.4,0.4-0.4,0.7-0.4s0.2,0.5,0.7,0.3s1.3-0.1,1.2-0.4s-0.9-0.3-0.8-0.5s-0.8-0.9-0.3-1s-0.1,0,0.3,0s-0.2-0.6,0.3-0.6
		s0,0.5,0.7,0.4c0.4-0.1-0.3-0.7,0.2-0.8c0.5-0.1-0.1-0.5,0.5-1c0.6-0.5,0.3-0.1,0.8-0.1c0.5-0.1,0.8-1,1.2-0.8s0.4-0.4,1.1-0.5
		s1,0.4,1.2,0.6c0.2,0.2,0.5,0.4,0.6,0.8c0.1,0.3-0.5,0.7-0.4,0.9s-0.3,0.8,0,1.1s0.2,0.1,0.8-0.3c0.6-0.4,0.4-0.8,0.5-1
		c0.1-0.2,0.8,0.1,1,0.2c0.2,0.1,0.8,0.5,1.2,0.3c0.4-0.2,0.7-0.9,0.5-1.1s-0.9-0.5-0.7-0.7s0.2-0.5,0.4-0.8s0.1-1,0.6-1
		c0.5,0,0.2-0.3,0.5-0.7c0,0-0.4-0.8,0.7-0.8s0.2-0.6,0.8-0.7s2.3,0.3,2.4-0.1c0.1-0.2,0.4-1.6,1.1-1.7c0.2,0,0.2-0.2,0.6,0.4
		c0.3,0.4,0.6,0.2,1.1,0.4c0.4,0.2,0.3,0.7,1.3,0.7c1,0,0.2-0.2,1-0.1s-0.2,0.4,0.5,0.4c0.7,0-0.1,0.5,1,0.5s0.4-0.7,1.2-0.8
		s1,0.4,0.6,0.7s-1.3,1.1-1.5,1.2c-0.2,0.1-0.8,1.6-0.6,2s-0.9,0.7-1,0.9c-0.1,0.2,0.7,1.1,0.7,1.1s0.8,0.2,1,0.4
		c0.2,0.1,0.1,1.4,0.8,1.2c0.5-0.2,1.3,0,1.7,0.2c0.2,0.1,0.3,0.3,0.3,0.3s0.9-0.1,0.8,0.3c-0.1,0.2,0.7,0.9,2,1.3
		c0.4,0.1,0.1,0.7,0.5,0.8s0.8,0.6,1.9,0.5c1.1-0.1,1.1,0,1.4-0.5c0.3-0.5,0.2-0.9,0.7-1.5c0.4-0.6,0.2-0.6,0.5-1
		c0.4-0.5-0.1-1.5,0.3-1.5s0.3-0.4,0.2-0.8s-0.8-3.1-0.1-3.2c0.4-0.1,0.1-0.5,0.1-0.8s0.6-0.4,0.6-0.6c0-0.3-0.4-0.4-0.3-0.7
		c0.1-0.2,0.1-0.5,0.4-1C246.5,390.4,246.4,389.1,246.8,389.2z"/>
	<path class="st4" d="M258.1,454.2c-0.2,0-1,0.3-1.2,0.4s-0.9,0.3-0.9,0.5s0.3,0.8,0,0.7c-0.3-0.1-0.1-0.5-0.6-0.5s-0.9,0.2-1.1,0
		s-0.3-0.5-0.7-0.5s-0.2-0.4-0.8-0.4c-0.5,0-0.2-0.4-0.7-0.4s-1.1-0.3-1,0s-0.1,0.8,0,1.1s0.6,0.3,0.5,0.5s-0.4,0.6,0,0.8
		s-0.2,0.7,0.4,0.7s0.5,0.4,0.5,0.5c0,0.1,0.4,0.3,0.5,0.4c0.1,0.2-0.6,0-0.6,0s-0.3,0.6,0,0.8s1.2,1.1,1,1.3s0.5,0.4,0.7,0.6
		s0,0.4,0.4,0.5c0.4,0.1,0.6,0.4,0.9,0.2c0.2-0.2-0.2-0.9,0.2-1s0.2-0.8,0.6-0.9c0.4-0.1,0.7,0,1-0.1c0.3-0.1,0.5-0.4,0.3-0.7
		c-0.2-0.3-0.2-0.4,0.1-0.6c0.3-0.2-0.4-0.7,0.1-0.9s0.5-0.6,0.6-1c0.1-0.3,0-1.1,0-1.4S258.1,454.2,258.1,454.2z"/>
</g>
<g>
	<rect x="444.6" y="93.2" class="st5" width="10" height="112.2"/>
	<g>
		<path class="st6" d="M539.9,98.3H359.3c-1,0-1.8-0.8-1.8-1.8V37.5c0-1,0.8-1.8,1.8-1.8h180.6c1,0,1.8,0.8,1.8,1.8v59.1
			C541.7,97.5,540.9,98.3,539.9,98.3z"/>
		<path class="st7" d="M534.6,93.2h-170c-1,0-1.8-0.8-1.8-1.8V42.6c0-1,0.8-1.8,1.8-1.8h170c1,0,1.8,0.8,1.8,1.8v48.9
			C536.4,92.4,535.6,93.2,534.6,93.2z"/>
		<text transform="matrix(1 0 0 1 369.8613 63.0625)" class="st8 st9 st10">TRAVEL </text>
		<text transform="matrix(1 0 0 1 369.8613 78.2125)" class="st8 st9 st10">SAVED</text>
		<text transform="matrix(1 0 0 1 513.2597 71.0166)" class="st8 st9 st11">Km</text>
	</g>
	<text transform="matrix(1 0 0 1 434.3408 72.1074)" class="st8 st9 st12">{{summary?.km | number}}</text>
</g>
<g>
	<path class="st13" d="M485.4,256.5c8.1,5.2,14.4-0.9,26.2-2.2c6.2-0.6,15-6.3,16.3-12.1c1.3-6,4.2-6.9,10.3-5.7
		c7.9,1.5,11.2-9.1,14.6-13.7c4.9-6.6-2.3-9.3,4.2-14.8c3.9-3.2-10.7-7.7-18-2.6c-2.8,1.9-5.5,5.2-8.2,10.4
		c-3.6,7.1-15.2,12.4-14.6,20.7c0.3,5.3-12,7.3-14.4,12.3C498.6,255.1,484.5,255.9,485.4,256.5z"/>
	<path class="st13" d="M564,140.3c-13.8,6.7,22.5,26.6,51.9,35.5s73.7,15.9,88.2,8.9c3.3-1.6,2.7-10.2,4.2-12.6
		c3-4.7,5.2-4.9-0.5-12.5c-3-4,2.8-10.1-3.3-21.8c-11.3-21.8-41.9-3.8-58.5-8.8c-16.4-5-29.2-16.5-43.8-9.2
		c-8.1,4.1-14.2,1.8-21.3,2.6C565,124.2,574.3,135.3,564,140.3z"/>
	<path class="st13" d="M527.1,222.4c4.2,6.1,13.2,9.3,24.9,9.7c3.6,0.1,7.4-0.1,11.5-0.4c15.7-1.6,34.4-7,52.9-16
		c38.9-19,62.1-46.8,51.8-62s-50.3-12.2-89.2,6.8c-20.3,9.9-36.3,22.2-45.5,33.9c-3.3,4.2,2.6,9.3-7.2,12.2
		C519.2,208.5,524.1,217.9,527.1,222.4z"/>
	<path class="st13" d="M533.2,164.5c-2.6,4.5,2,8.4-1.3,14.2c-7.8,13.8,27.8,29.4,59.9,37c21.7,5.1,50.2,15.5,61.1,3.3
		c10.4-11.6,19.4-1.6,26-1.9c16.4-0.7,14.8-10,19.1-11.2c14.6-4,0.5-23-1.1-27.9c-5-14.6-35.1-20.4-67.2-28.1
		c-25.7-6.1-46.9-14.5-65.7-9.7c-7.4,1.9-21.8,6.8-23.2,16.3C540.2,160.2,534.9,161.6,533.2,164.5z"/>
	<path class="st13" d="M599.7,184.8c17.5,9.2,47.4,0.2,66.7-20c9.6-10,43.3-29.5,24.1-39.6c-6-3.1-11.6,1.6-12.9-7
		c-1.9-12.3-29.2-16.3-35.5-9c-6.2,7.2-16.9-6.3-22.4,9c-2.2,6-11.7,8.1-16.7,13.3C583.7,151.8,582.2,175.7,599.7,184.8z"/>
	<path class="st13" d="M556,171c-13.7,10.1-9,31.4,10.5,47.5c5.6,4.6,16,3.6,18.1,10.9c1.9,6.8,10.2,3.1,17,3.3
		c8,0.3,19.5,1.2,20.6-4.6c1.3-6.8,9.4-3.8,10.7-6.8c4.7-11.1-1.5-26.9-16.8-39.6C596.6,165.7,569.7,160.9,556,171z"/>
</g>
<g>
	<g>
		
			<ellipse transform="matrix(0.1735 -0.9848 0.9848 0.1735 123.1775 278.6752)" class="st14" cx="227.6" cy="65.9" rx="36" ry="36"/>
		<circle class="st14" cx="271.3" cy="56.6" r="36"/>
		<circle class="st14" cx="294.2" cy="84.6" r="36"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 110.0446 333.2219)" class="st14" cx="250.9" cy="101.9" rx="36" ry="36"/>
	</g>
	<circle class="st15" cx="174.9" cy="83.9" r="65.8"/>
	<g>
		<g>
			<g>
				<text transform="matrix(1 0 0 1 124.6904 87.168)" class="st16 st9 st10">TOTAL NUMBER </text>
				<text transform="matrix(1 0 0 1 132.3604 102.318)" class="st16 st9 st10">OF CONSULTS</text>
				<text transform="matrix(1 0 0 1 161.0244 123.8301)" class="st16 st9 st12">{{consults | number}}</text>
			</g>
			<path class="st17" d="M180.2,39.5c-1.1,0-2.1,0.9-2.1,2c0,0.1,0,0.2,0,0.2c0.1,1,1,1.8,2,1.8s1.9-0.8,2-1.8c0-0.1,0-0.2,0-0.2
				C182.2,40.4,181.3,39.5,180.2,39.5"/>
		</g>
		<g>
			<path class="st17" d="M173.6,60.4h13.1c-1.5-2-3.9-3.3-6.6-3.3C177.5,57.1,175.1,58.4,173.6,60.4"/>
			<path class="st17" d="M175.5,51c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7C177.6,46.4,175.5,48.5,175.5,51"/>
			<path class="st17" d="M166.8,43.7h11c-0.5-0.5-0.8-1.2-0.8-2h-10.3c-1,0-1.9,0.8-1.9,1.9v1.5c0.7,0,1.3,0.2,2,0.3L166.8,43.7
				L166.8,43.7z"/>
			<path class="st17" d="M193.7,41.8h-10.3c-0.1,0.8-0.4,1.4-0.8,2h11v17.9h-25.6c1.3,0.4,2.5,1.1,3.6,2h4h9.1h9
				c1,0,1.9-0.8,1.9-1.9V43.6C195.6,42.6,194.8,41.8,193.7,41.8"/>
			<path class="st17" d="M169.9,63.6c-1.6-1-3.3-1.5-5.1-1.6c-0.2,0-0.3,0-0.5,0c-4.2,0-7.9,2.6-10.2,6.5h20.4
				C173.4,66.4,171.8,64.7,169.9,63.6"/>
			<path class="st17" d="M166.8,46.6c-0.6-0.2-1.3-0.3-2-0.4c-0.1,0-0.3,0-0.4,0c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2
				c0.1,0,0.3,0,0.4,0c0.7,0,1.3-0.2,2-0.4c2.8-1,4.8-3.7,4.8-6.8C171.6,50.2,169.6,47.6,166.8,46.6"/>
		</g>
	</g>
</g>
<g>
	<text transform="matrix(1 0 0 1 646.1181 188.708)" class="st16 st9 st11">Kg CO</text>
	<text transform="matrix(1 0 0 1 675.168 192.0715)" class="st16 st9 st18">2</text>
	<text transform="matrix(1 0 0 1 678.5292 188.708)" class="st16 st9 st11"> </text>
	<text transform="matrix(1 0 0 1 581.3305 190.1113)" class="st16 st9 st12">{{summary?.co2 | number}}</text>
</g>
<path class="st19" d="M572.4,430.7c30.3-24.9,166.6-25.1,166.6-8c0,40.1,0,99.6,0,99.6L554,521.5C555,508.7,545.4,452.9,572.4,430.7
	z"/>
<path class="st20" d="M279.2,197.7c8.8,3.4,30.9-0.3,66.6,21.9c21.6,13.4,31.7,14.7,56.9,32.7c18.1,12.9,103.2-0.4,122.3,19.9
	c28,29.8,14.7,86.2,16.7,117.9c6.7,106.5,39.6,132.3,39.6,132.3H322.5c0,0,32.6-5.9,80.2-38.2c24.2-16.4,83.7-107.9,61.3-176.2
	c-11-33.5-77.9-31.6-106.8-50.4c-35.6-23.1-92.4-31.5-92.7-34.4C261.6,201.3,279.2,197.7,279.2,197.7z"/>
<path class="st21" d="M318.6,220.9c-3.4-1.4-24.9-11-33.9-12.7"/>
<path class="st22" d="M498.2,436.1c2.8-13.9,3.4-26.5,3.4-42.1"/>
<path class="st22" d="M504.9,360c1.1-19.1-0.3-33.1-3.5-45"/>
<path class="st22" d="M491.5,288.3c-6.6-15.7-17.5-15.3-27.9-18.2"/>
<path class="st23" d="M438,268.1c-5.5-2.3-31.4-4.3-38.7-6"/>
<g>
	<path class="st24" d="M484.2,252.9h0.4c0.9,0,1.5-0.7,1.5-1.6v-3.6c0-0.9-0.7-1.6-1.5-1.6h-0.4c-0.9,0-1.5,0.7-1.5,1.6v3.6
		C482.6,252.2,483.4,252.9,484.2,252.9z"/>
	<path class="st16" d="M468.5,222.4c-1.4-0.6-32.9-0.6-34.6,0.2c-2.5,1.2-14.7,16.6-13.7,16.8c2,0.3,56.9,0.3,58.5-0.1
		C480.4,238.8,470,223,468.5,222.4z"/>
	<path class="st25" d="M398.1,257.6l82-0.1c5.2,0,4.2-4.2,4.2-9.4l0,0c0-5.2-2.4-9.4-9.4-9.4s-53.6,0.1-53.6,0.1
		c0.7-1.8,1.5-4-0.1-3.4c-1.6,0.6-8.3,0.4-17.6,3c-6.1,1.7-6.5,2.8-9.8,9.2v1.1C393.8,253.8,393,257.6,398.1,257.6z"/>
	<path class="st26" d="M393,259.5l91.7-0.1c0.7,0,1.3-0.6,1.3-1.3l0,0c0-0.7-0.3-3.1-2-3.1c-2.9,0-4,1.7-4,1.7l-79.9,0.1
		c0,0-0.7-2.3-7-2.3c-1.3,0-1.4,2.9-1.4,3.7l0,0C391.7,258.9,392.3,259.5,393,259.5z"/>
	<circle class="st16" cx="466.1" cy="259.4" r="9.8"/>
	<circle class="st26" cx="466.1" cy="259.4" r="6.1"/>
	<circle class="st16" cx="411.5" cy="259.2" r="9.8"/>
	<circle class="st26" cx="411.5" cy="259.2" r="6.1"/>
	<path class="st27" d="M466.1,226.2c-0.9,0-14.3-0.6-14.1,0c0.2,0.6,0.3,10.5,0,10.8s19.1,0.2,20.1,0.2
		C473.2,237.2,467.3,226.2,466.1,226.2z"/>
	<path class="st27" d="M435,225.9c0.9,0,12.6-0.3,12.6,0.3c0,1.2,0,9.1,0,10.8c0,0.5-19.7,0.2-20.7,0.2
		C425.9,237.2,433.8,225.9,435,225.9z"/>
	<path class="st27" d="M431.3,225c-0.9,0.3-10.1,10.4-9.7,11.4s-1.2,3,0.6,2.2c1.4-0.7,10.6-11.9,10.6-12.6
		C432.8,225.2,431.3,225,431.3,225z"/>
	<path class="st28" d="M483.5,252.9h0.2c0.4,0,0.8-0.4,0.8-0.8l0-5.1c0-0.4-0.4-0.8-0.8-0.8h-0.2c-0.4,0-0.8,0.4-0.8,0.8l0,5.1
		C482.7,252.6,483,252.9,483.5,252.9z"/>
	<path class="st24" d="M393.4,252.4h-0.5c-0.7,0-1.2-0.5-1.2-1.2v-2.4c0-0.7,0.5-1.2,1.2-1.2h0.5c0.7,0,1.2,0.5,1.2,1.2v2.4
		C394.6,251.9,394.1,252.4,393.4,252.4z"/>
	<path class="st28" d="M394,252.4h-0.3c-0.3,0-0.6-0.3-0.6-0.6v-3.6c0-0.3,0.3-0.6,0.6-0.6h0.3c0.3,0,0.6,0.3,0.6,0.6v3.6
		C394.6,252.1,394.3,252.4,394,252.4z"/>
</g>
<path class="st23" d="M373.1,249c-3.1-2-22.8-14.9-31.3-18.1"/>
<rect x="107.5" y="210.4" class="st29" width="196.1" height="104"/>
<rect x="102.9" y="196.1" class="st30" width="205.3" height="14.5"/>
<g>
	<rect x="188.7" y="289.6" class="st31" width="16.4" height="24.8"/>
	<rect x="206.8" y="289.6" class="st31" width="16.4" height="24.8"/>
</g>
<polygon class="st32" points="205.6,157.8 102.9,189.8 308.2,189.8 "/>
<rect x="113.6" y="190" class="st32" width="184" height="5.9"/>
<text transform="matrix(1 0 0 1 194.6513 206.5918)" class="st16 st9 st33">BANK</text>
<polygon class="st34" points="205.6,165.1 128.6,189 282.6,189 "/>
<text transform="matrix(1 0 0 1 159.7622 279.166)" class="st16 st9 st12">{{summary?.costs| currency:"AUD": "$":"4.0"}}</text>
<rect x="204.1" y="464.2" class="st16" width="13.6" height="49.7"/>
<path class="st16" d="M164.1,379.4h-13.2c0-3.7,3-6.6,6.6-6.6C161.1,372.8,164.1,375.8,164.1,379.4z"/>
<path class="st16" d="M217.6,379.4h-13.2c0-3.7,3-6.6,6.6-6.6C214.6,372.8,217.6,375.8,217.6,379.4z"/>
<path class="st16" d="M271.1,379.4h-13.2c0-3.7,3-6.6,6.6-6.6C268.1,372.8,271.1,375.8,271.1,379.4z"/>
<line class="st35" x1="264.5" y1="379.4" x2="264.5" y2="386.4"/>
<line class="st35" x1="210.9" y1="379.4" x2="210.9" y2="386.4"/>
<line class="st35" x1="157.4" y1="379.4" x2="157.4" y2="386.4"/>
<text transform="matrix(1 0 0 1 270.3476 429.9365)" class="st16 st9 st12">{{trips(summary?.km) | number}}</text>
<g>
	<path class="st36" d="M434.6,398.8h-98.8v-83.2c0-8.6,7-15.6,15.6-15.6H419c8.6,0,15.6,7,15.6,15.6V398.8z"/>
	<g>
		<line class="st37" x1="394.2" y1="310.4" x2="394.2" y2="318.2"/>
		<line class="st37" x1="399" y1="318.2" x2="394.2" y2="318.2"/>
		<path class="st37" d="M399,315v15c0,1.5-1.9,3.8-4.4,3.8H389"/>
		<line class="st37" x1="393.1" y1="309.2" x2="399" y2="315"/>
		<path class="st26" d="M392.7,341h-21.3v-33c0-1.1,0.9-1.9,1.9-1.9h17.4c1.1,0,1.9,0.9,1.9,1.9V341z"/>
		<path class="st17" d="M386.9,319.9h-9.5c-0.6,0-1.2-0.5-1.2-1.2v-7.5c0-0.6,0.5-1.2,1.2-1.2h9.5c0.6,0,1.2,0.5,1.2,1.2v7.5
			C388.1,319.4,387.5,319.9,386.9,319.9z"/>
	</g>
	<text transform="matrix(1 0 0 1 342.0981 362.8867)" class="st16 st9 st10">FUEL SAVINGS</text>
	<text transform="matrix(1 0 0 1 362.3071 385.5713)" class="st16 st9 st12">{{fuel(summary?.km) | number}}L</text>
	<text transform="matrix(1 0 0 1 407.5678 385.1279)" class="st16 st9 st11"></text>
</g>
<g>
	<text transform="matrix(1 0 0 1 144.601 229.8115)" class="st16 st9 st10">SAVINGS IN TRAVEL, </text>
	<text transform="matrix(1 0 0 1 151.791 243.4515)" class="st16 st9 st10">ACCOMODATION  </text>
	<text transform="matrix(1 0 0 1 137.331 257.0815)" class="st16 st9 st10">&amp; LOST PRODUCTIVITY</text>
</g>
<g>
	<g>
		<rect x="112.4" y="281.2" class="st8" width="16.4" height="26.8"/>
		<rect x="132.9" y="281.2" class="st8" width="16.4" height="26.8"/>
	</g>
	<line class="st38" x1="141.1" y1="281.2" x2="141.1" y2="308"/>
	<line class="st38" x1="120.6" y1="281.2" x2="120.6" y2="308"/>
	<line class="st39" x1="149.4" y1="290.2" x2="112.4" y2="290.2"/>
</g>
<g>
	<g>
		<rect x="263" y="281.2" class="st8" width="16.4" height="26.8"/>
		<rect x="283.6" y="281.2" class="st8" width="16.4" height="26.8"/>
	</g>
	<line class="st38" x1="291.8" y1="281.2" x2="291.8" y2="308"/>
	<line class="st38" x1="271.2" y1="281.2" x2="271.2" y2="308"/>
	<line class="st39" x1="300" y1="290.2" x2="263" y2="290.2"/>
</g>
<g>
	<rect x="110.4" y="216.6" class="st40" width="15.7" height="94.7"/>
	<rect x="105.6" y="210.4" class="st40" width="25.4" height="4.3"/>
	<rect x="108.3" y="214.7" class="st40" width="19.9" height="3.8"/>
	<rect x="105.6" y="315" class="st40" width="25.4" height="4.3"/>
	<rect x="108.3" y="311.2" class="st40" width="19.9" height="3.8"/>
</g>
<g>
	<rect x="286" y="216.6" class="st40" width="15.7" height="94.7"/>
	<rect x="281.1" y="210.4" class="st40" width="25.4" height="4.3"/>
	<rect x="283.9" y="214.7" class="st40" width="19.9" height="3.8"/>
	<rect x="281.1" y="315" class="st40" width="25.4" height="4.3"/>
	<rect x="283.9" y="311.2" class="st40" width="19.9" height="3.8"/>
</g>
<g>
	<circle class="st1" cx="629.2" cy="254.2" r="36"/>
	<circle class="st1" cx="672.9" cy="244.9" r="36"/>
	<circle class="st1" cx="695.7" cy="272.8" r="36"/>
	<circle class="st1" cx="652.4" cy="290.2" r="36"/>
</g>
<circle class="st1" cx="598.1" cy="246.4" r="36"/>
<text transform="matrix(1 0 0 1 558.6586 251.7431)" class="st8 st9 st10">THAT’S EQUIVALENT TO</text>
<g>
	<path class="st28" d="M661.2,445.1h-18.9c-0.1,0-0.1-0.1-0.1-0.2l5.7-125.4c0-0.1,0-0.2,0.1-0.2h7.5c0.1,0,0.1,0.1,0.1,0.2
		L661.2,445.1z"/>
	<path class="st6" d="M710.5,301.5C710.5,301.5,710.4,301.5,710.5,301.5c1.4-3.2,2-6.6,1.6-10.1c-2-16.5-26.2-29.5-53.8-29.1
		c-15.7,0.2-29.1,4.7-37.5,11.5c-14.6-3-28.3-0.8-35.9,6.9c-7.4,7.4-7.4,18.3-1.4,28.9c-5,1.3-9.3,3.6-12.6,6.8
		c-12.3,12.4-4.1,34.3,18.2,49c19.9,13.1,44.4,16,58.1,7.7c19.2,7.8,39.3,7.1,49.2-2.9c3.3-3.3,5.1-7.4,5.6-11.8
		c11,0.6,20.7-2.1,26.7-8.1C741,338.2,732.8,316.2,710.5,301.5z"/>
</g>
<text transform="matrix(1 0 0 1 624.8222 330.292)" class="st8 st9 st12">{{tree(summary?.co2) | number}}</text>
<text transform="matrix(1 0 0 1 650.0434 291.3418)" class="st8 st9 st10"> </text>
<text transform="matrix(1 0 0 1 618.5934 306.4918)" class="st8 st9 st10">PLANTING</text>
<text transform="matrix(1 0 0 1 631.9184 350.9766)" class="st8 st9 st10">TREES</text>
<text transform="matrix(1 0 0 1 556.4999 166.5996)" class="st16 st9 st10">CO</text>
<text transform="matrix(1 0 0 1 574.4404 170.8039)" class="st16 st9 st41">2 </text>
<text transform="matrix(1 0 0 1 580.5541 166.5996)" class="st16 st9 st10">EMISSIONS SAVED</text>
<g>
	<g>
		<g>
			<path class="st28" d="M699.8,453.2h-5.6c0,0,0,0,0,0l1.7-37.1c0,0,0,0,0,0h2.2c0,0,0,0,0,0L699.8,453.2z"/>
			<path class="st6" d="M709.8,402.6C709.8,402.6,709.8,402.6,709.8,402.6c0.2-1,0.2-2,0-3.1c-1.1-4.8-7.3-7.3-13.7-5.8
				c-3.6,0.9-6.6,2.9-8.2,5.3c-3.5-0.1-6.6,1.2-8.1,3.9c-1.4,2.6-1,5.8,0.9,8.6c-1.1,0.6-2,1.5-2.6,2.7c-2.3,4.3,0.4,10.3,6.2,13.5
				c5.2,2.8,11,2.4,13.8-0.7c4.8,1.3,9.4,0.1,11.3-3.4c0.6-1.2,0.9-2.4,0.8-3.8c2.6-0.4,4.7-1.7,5.9-3.8
				C718.4,411.9,715.6,405.8,709.8,402.6z"/>
		</g>
		<g>
			<path class="st28" d="M604.4,453.2h-5.6c0,0,0,0,0,0l1.7-37.1c0,0,0,0,0,0h2.2c0,0,0,0,0,0L604.4,453.2z"/>
			<path class="st6" d="M615.5,396C615.5,396,615.5,396,615.5,396c0.2-1.1,0.2-2.2,0-3.3c-1.2-5.1-7.8-7.9-14.8-6.2
				c-3.9,0.9-7.1,3.1-8.9,5.7c-3.8-0.1-7.1,1.3-8.7,4.2c-1.5,2.8-1,6.2,0.9,9.3c-1.2,0.7-2.2,1.6-2.8,2.9
				c-2.5,4.6,0.5,11.1,6.7,14.6c5.6,3.1,11.8,2.6,14.9-0.8c5.1,1.4,10.2,0.1,12.2-3.6c0.7-1.2,1-2.6,0.9-4.1
				c2.8-0.4,5.1-1.8,6.3-4.1C624.8,405.9,621.8,399.4,615.5,396z"/>
		</g>
		<g>
			<path class="st28" d="M651.7,522.3h-5.6c0,0,0,0,0,0l1.7-37.1c0,0,0,0,0,0h2.2c0,0,0,0,0,0L651.7,522.3z"/>
			<path class="st6" d="M660.4,474.4C660.4,474.4,660.4,474.4,660.4,474.4c0.2-0.9,0.2-1.8,0-2.7c-1-4.2-6.5-6.5-12.2-5.1
				c-3.2,0.8-5.8,2.6-7.3,4.7c-3.1-0.1-5.9,1.1-7.2,3.5c-1.2,2.3-0.9,5.1,0.8,7.6c-1,0.6-1.8,1.4-2.3,2.4c-2.1,3.8,0.4,9.2,5.5,12
				c4.6,2.5,9.7,2.2,12.3-0.6c4.2,1.2,8.4,0.1,10-3c0.6-1,0.8-2.2,0.7-3.3c2.3-0.4,4.2-1.5,5.2-3.3
				C668,482.6,665.6,477.2,660.4,474.4z"/>
		</g>
		<g>
			<path class="st28" d="M678.6,499.9H673c0,0,0,0,0,0l1.7-37.1c0,0,0,0,0,0h2.2c0,0,0,0,0,0L678.6,499.9z"/>
			<path class="st6" d="M689.1,444.1C689.1,444.1,689.1,444.1,689.1,444.1c0.2-1,0.2-2.1,0-3.1c-1.2-4.9-7.5-7.5-14-5.9
				c-3.7,0.9-6.7,3-8.5,5.5c-3.6-0.1-6.8,1.3-8.3,4c-1.4,2.6-1,5.9,0.9,8.8c-1.1,0.7-2.1,1.6-2.7,2.7c-2.4,4.4,0.5,10.6,6.4,13.8
				c5.3,2.9,11.2,2.5,14.2-0.7c4.9,1.3,9.7,0.1,11.6-3.5c0.7-1.2,0.9-2.5,0.8-3.9c2.6-0.4,4.8-1.7,6-3.9
				C697.9,453.6,695,447.4,689.1,444.1z"/>
		</g>
		<g>
			<path class="st28" d="M624.8,499.9h-5.6c0,0,0,0,0,0l1.7-37.1c0,0,0,0,0,0h2.2c0,0,0,0,0,0L624.8,499.9z"/>
			<path class="st6" d="M634.3,444.1C634.2,444.1,634.2,444.1,634.3,444.1c0.2-1,0.2-2.1,0-3.1c-1.2-4.9-7.5-7.5-14-5.9
				c-3.7,0.9-6.7,3-8.5,5.5c-3.6-0.1-6.8,1.3-8.3,4c-1.4,2.6-1,5.9,0.9,8.8c-1.1,0.7-2.1,1.6-2.7,2.7c-2.4,4.4,0.5,10.6,6.4,13.8
				c5.3,2.9,11.2,2.5,14.2-0.7c4.9,1.3,9.7,0.1,11.6-3.5c0.7-1.2,0.9-2.5,0.8-3.9c2.6-0.4,4.8-1.7,6-3.9
				C643,453.6,640.2,447.4,634.3,444.1z"/>
		</g>
	</g>
	<g>
		<path class="st28" d="M713.6,513.1H708c0,0,0,0,0,0l1.7-37.1c0,0,0,0,0,0h2.2c0,0,0,0,0,0L713.6,513.1z"/>
		<path class="st6" d="M720.9,464.1L720.9,464.1c0.2-0.8,0.2-1.6,0-2.4c-0.9-3.7-5.6-5.7-10.6-4.5c-2.8,0.7-5.1,2.2-6.4,4.1
			c-2.7-0.1-5.1,1-6.2,3c-1.1,2-0.8,4.5,0.7,6.6c-0.9,0.5-1.6,1.2-2,2c-1.8,3.3,0.3,8,4.8,10.4c4,2.2,8.5,1.9,10.7-0.6
			c3.7,1,7.3,0,8.7-2.6c0.5-0.9,0.7-1.9,0.6-2.9c2-0.3,3.7-1.3,4.5-2.9C727.5,471.2,725.3,466.6,720.9,464.1z"/>
	</g>
	<g>
		<path class="st28" d="M590.4,513.1h-5.6c0,0,0,0,0,0l1.7-37.1c0,0,0,0,0,0h2.2c0,0,0,0,0,0L590.4,513.1z"/>
		<path class="st6" d="M597.6,464.1L597.6,464.1c0.2-0.8,0.2-1.6,0-2.4c-0.9-3.7-5.6-5.7-10.6-4.5c-2.8,0.7-5.1,2.2-6.4,4.1
			c-2.7-0.1-5.1,1-6.2,3c-1.1,2-0.8,4.5,0.7,6.6c-0.9,0.5-1.6,1.2-2,2c-1.8,3.3,0.3,8,4.8,10.4c4,2.2,8.5,1.9,10.7-0.6
			c3.7,1,7.3,0,8.7-2.6c0.5-0.9,0.7-1.9,0.6-2.9c2-0.3,3.7-1.3,4.5-2.9C604.2,471.2,602.1,466.6,597.6,464.1z"/>
	</g>
</g>
<text transform="matrix(1 0 0 1 126.1598 411.5287)"><tspan x="0" y="0" class="st16 st9 st42">NO. OF TRIPS  </tspan><tspan x="0" y="13.2" class="st16 st9 st42">SAVED TRAVERSING</tspan><tspan x="0" y="26.4" class="st16 st9 st42">AUSTRALIA’S  </tspan><tspan x="0" y="39.6" class="st16 st9 st42">COASTLINE</tspan></text>
</svg>
