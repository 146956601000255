import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { kgCo2PerKm, kgCo2PerConsult } from '../summary/constants';

@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent {
  summary?: SummaryPerType;
  closeResult: String = '';

  formModeSwitch = new FormControl('simple');
  periodModeSwitch = new FormControl('annual');
  editClinicianExpenses = new FormControl(false);
  editServiceExpenses = new FormControl(false);

  mainForm = new FormGroup({
    videoCall: new FormGroup({
      consults: new FormControl(5000, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      meetings: new FormControl(750, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      clinicalSites: new FormControl(50, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      clinicians: new FormControl(150, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    }),
    healthConsumer: new FormGroup({
      patientTrip: new FormControl(25, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      travelCost: new FormControl(30, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      lostProductivity: new FormControl(125, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    }),
    healthSystem: new FormGroup({
      clinicianTravel: new FormControl(50, [Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      clinicianTravelCost: new FormControl(250, [Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      clinicianLostProductivity: new FormControl(250, [Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      techSupport: new FormControl(50, [Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    }),
    equipmentService: new FormGroup({
      capitalEquipment: new FormControl(1000, [Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      maintenance: new FormControl(100, [Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
      serviceOrLicense: new FormControl(30, [Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    })
  });

  videoCall(name: string): FormControl { return this.mainForm.get('videoCall')!!.get(name) as FormControl; }
  healthConsumer(name: string): FormControl { return this.mainForm.get('healthConsumer')!!.get(name) as FormControl; }
  healthSystem(name: string): FormControl { return this.mainForm.get('healthSystem')!!.get(name) as FormControl; }
  equipmentService(name: string): FormControl { return this.mainForm.get('equipmentService')!!.get(name) as FormControl; }

  isSimpleMode(): boolean {
    return this.formModeSwitch.value === 'simple';
  }

  isAnnualMode(): boolean {
    return this.periodModeSwitch.value === 'annual';
  }

  calculate(): void {
    if (this.mainForm.valid) {
      const index = (this.isAnnualMode()) ? 12 : 1;
      const form = this.mainForm.getRawValue();

      const patientTravelSaveKm = form.healthConsumer.patientTrip * form.videoCall.consults * index;
      const patientTravelSaveKgCo2 = patientTravelSaveKm * kgCo2PerKm - kgCo2PerConsult * form.videoCall.consults * index;

      const travelCostSave = form.healthConsumer.travelCost * form.videoCall.consults * index;
      const travelProductivityLostSave = form.healthConsumer.lostProductivity * form.videoCall.consults * index;
      const consumersSave = travelCostSave + travelProductivityLostSave;

      const logisticsSaveKm = (this.editClinicianExpenses.value === true) ? form.healthSystem.clinicianTravel * form.videoCall.meetings * index : 0;
      const logisticsSaveKgCo2 = logisticsSaveKm * kgCo2PerKm - kgCo2PerConsult * form.videoCall.meetings * index;


      let clinicianSave = 0;
      if (this.editClinicianExpenses.value === true) {
        const clinicianTravelCostSave = form.healthSystem.clinicianTravelCost * form.videoCall.meetings * index;
        const clinicianProductivityLostSave = form.healthSystem.clinicianLostProductivity * form.videoCall.meetings * index;
        const techSupportSave = form.healthSystem.techSupport * form.videoCall.consults * index; //use same number .meetings
        clinicianSave = clinicianTravelCostSave + clinicianProductivityLostSave + techSupportSave;
      }

      let equipmentOrServiceSave = 0;
      if (this.editServiceExpenses.value === true) {
        const capitalEquipmentSave = form.equipmentService.capitalEquipment * form.videoCall.clinicalSites;
        const maintenanceSave = form.equipmentService.maintenance * form.videoCall.clinicalSites;
        const serviceOrLicenseSave = form.equipmentService.serviceOrLicense * form.videoCall.clinicians * index;
        equipmentOrServiceSave = capitalEquipmentSave + maintenanceSave + serviceOrLicenseSave;
      }

      this.summary = {
        consumers: {
          costs: consumersSave,
          km: patientTravelSaveKm,
          co2: patientTravelSaveKgCo2
        },
        clinician: {
          costs: clinicianSave,
          km: logisticsSaveKm,
          co2: logisticsSaveKgCo2
        },
        equipmentOrService: {
          costs: equipmentOrServiceSave
        }
      };
    }
  }

  constructor( private modalService: NgbModal) {
  }

  open(content: any) {
    this.calculate();

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementsByClassName('printable')!!.item(0)!!.innerHTML;

    popupWin = window.open('', '_blank')!!;
    popupWin.document.open();
    popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="styles.css" /></head><body onload="setTimeout(() => {window.print(); window.close()}, 100)">' + printContents + '</body></html>');
    popupWin.document.close();
  }
}

export interface Summary {
  costs?: number;
  km?: number;
  co2?: number;
}

export interface SummaryPerType {
  consumers?: Summary;
  clinician?: Summary;
  equipmentOrService?: Summary;
}
